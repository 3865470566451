<modal-sale-create>

    <!-- Modal -->
    <div class="modal fade" id="{state.elid}" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Create a Sale from Commission Report</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label for="sale_name" class="form-label">Name of Sale</label>
                                <input type="text" class="form-control" id="sale_name" aria-describedby="name_of_sale">
                                <div id="emailHelp" class="form-text">A simple identifier could be a date and/or name.</div>
                            </div>



                            <div class="mb-3">
                                <label for="sale_date_contract" class="form-label">Contract Date</label>
                                <input type="date" class="form-control" id="sale_date_contract" aria-describedby="name_of_sale" >
                            </div>

                            <div class="mb-3">
                                <label for="sale_date_closing" class="form-label">Closing Date</label>
                                <input type="date" class="form-control" id="sale_date_closing" aria-describedby="name_of_sale" >
                            </div>

                            <div class="mb-3">
                                <label for="sale_description" class="form-label">Description</label>
                                <textarea type="text" class="form-control" id="sale_description" aria-describedby="name_of_sale"></textarea>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3">
                                <label for="sale_amount" class="form-label">Amount of Sale</label>
                                <input type="hidden" class="form-control" id="sale_amount" aria-describedby="date_of_sale">
                                <input-currency hidden-id="sale_amount" placeholder="$ of sale" aria-describedby="sale_amount"></input-currency>
                                <div id="sale_amountHelp" class="form-text">Total amount of the sale</div>
                            </div>

                            <div class="mb-3">
                                <label for="sale_currency" class="form-label">Currency of Sale</label>
                                <select id="sale_currency" class="form-select form-control">
                                    <option value="">-- select --</option>
                                    <option value="USD">USD</option>
                                    <option value="MXN">MXN</option>
                                </select>
                            </div>
                            
                            <div class="mb-3">
                                <label for="sale_commission_percentage">Commission Realty Percent (%)</label>
                                <input type="number" class="form-control" id="sale_commission_percentage" aria-describedby="commission_percent">
                            </div>

                            <div class="mb-3">
                                <label for="sale_commission" class="form-label">Commission Realty ($)</label>
                                <input type="hidden" class="form-control" id="sale_commission" aria-describedby="date_of_sale">
                                <input-currency hidden-id="sale_commission" placeholder="$ of commission" ></input-currency>
                                <div id="sale_commissionHelp" class="form-text">Total amount of the commission realty side receives</div>
                            </div>
                        </div>
                    </div>



                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" onclick="{onSave}">Save changes</button>
                </div>
            </div>
        </div>
    </div>

    <script>

        import PubSub from "pubsub-js";
        import dayjs from "dayjs";
        import Schema from "async-validator";
        import InputCurrency from "../inputs/input-currency.riot";
        import {api_post} from "../../lib/api";
        import Swal from "sweetalert2";

        export default {
            components: {
                "input-currency": InputCurrency
            },
            state: {
                date_closing: '',
                date_contract: '',
                name: '',
                description: '',
                commission: '',
                amount_sale: '',
                isEdit: false,
                editId: null
            },
            onBeforeMount(props, state) {
                this.state.elid = 'modal-sale-create' + this.genId();

                if(props.sale){
                    state.isEdit = true;
                    state.editId = props.sale.id;
                    this.state.date_closing = props.sale.date_closing;
                    this.state.date_contract = props.sale.date_contract;
                    this.state.name = props.sale.name;
                    this.state.description = props.sale.description;
                    this.state.commission = props.sale.commission;
                    this.state.amount_sale = props.sale.amount_sale;
                }
            },
            getModal(){
                return document.getElementById(this.state.elid);
            },
            onMounted() {
                this.events = [];
                this.modal = new bootstrap.Modal(this.getModal(),{});
                const event1 = PubSub.subscribe('model.sale.create.show', this.onShow.bind(this));
                const event2 = PubSub.subscribe('model.sale.create.hide', this.onHide.bind(this));

                this.events.push(event1, event2);

            },
            onUnmounted() {
                this.events.forEach(e => PubSub.unsubscribe(e));
                this.modal.dispose();
            },
            onShow(msg,data){

                if(typeof data == 'object' && data.sale){
                    console.log("Edit Sale", data);

                    data = data.sale;

                    this.update({
                        date_closing: data.closing_date,
                        date_contract: data.contract_date,
                        name: data.short_name,
                        commission: data.sale_commission_percentage,
                        amount_sale: data.sale_commission,
                    });

                    this.modal.show();

                    setTimeout(()=>{
                        console.log('SSet Fields')
                        this.$('#sale_date_closing').value = dayjs(data.date_closing).format('YYYY-MM-DD');
                        this.$('#sale_date_contract').value = dayjs(data.date_contract).format('YYYY-MM-DD');
                        this.$('#sale_name').value = data.short_name;
                        this.$('#sale_commission_percentage').value = data.sale_commission_percentage;
                        this.$('#sale_currency').value = data.sale_currency;
                        this.$('#sale_description').value = data.description;

                        const $sale_amount = this.$('#sale_amount');
                        $sale_amount.value = data.sale_amount;
                        const $commission_total = this.$('#sale_commission');
                        $commission_total.value = data.sale_commission || 0;

                        setTimeout(()=>{
                            const cng = new Event('change');
                            $sale_amount.dispatchEvent(cng);
                            $commission_total.dispatchEvent(cng);
                        },100)

                        this.sale_id = data.ID;
                    },100);

                    return;
                }else{
                    console.log("Not Sale");
                    this.sale_id = null;
                }

                console.log('model.sale.create.show', data);

                this.update({
                    date_closing: data.closing_date,
                    date_contract: data.contract_date,
                    name: data.name,
                    commission: data.commission,
                    amount_sale: data.commission_price,
                });

                this.commission_id = data.ID;

                this.modal.show();

                setTimeout(()=>{
                    this.$('#sale_date_closing').value = dayjs(data.date_closing).format('YYYY-MM-DD');
                    this.$('#sale_date_contract').value = dayjs(data.date_contract).format('YYYY-MM-DD');
                    this.$('#sale_name').value = data.name || 'Sale ' + dayjs(data.date_closing).format('YYYY-MM-DD');

                    this.$('#sale_commission_percentage').value = data.commission_agency || 0;


                    this.$('#sale_currency').value = data.commission_currency || "";

                    const $sale_amount = this.$('#sale_amount');
                    $sale_amount.value = data.commission_price;


                    const $commission_total = this.$('#sale_commission');
                    $commission_total.value = data.commission_agency_total || 0;

                    setTimeout(()=>{
                        const cng = new Event('change');
                        $sale_amount.dispatchEvent(cng);
                        $commission_total.dispatchEvent(cng);
                    },100)

                },15)
            },
            onHide(){
                this.modal.hide();
            },
            verify(data){
                const desc = {
                    short_name: {
                        type: 'string',
                        required: true,
                        message: 'Please enter a short name for the sale.'
                    },
                    date_closing: {
                        type: 'date',
                        required: true,
                        message: 'Please enter a closing date for the sale.'
                    },
                    date_contact: {
                        type: 'date',
                        required: true,
                        message: 'Please enter a contract date for the sale.'
                    },
                    sale_amount: {
                        type: 'number',
                        required: true,
                        message: 'Please enter a sales amount'
                    },
                    sale_commission: {
                        type: 'number',
                        required: true,
                        message: 'Please enter a commission amount for the sale.'
                    },
                    sale_commission_percentage: {
                        type: 'number',
                        required: true,
                        message: 'Please enter a commission percentage for the sale.'
                    },
                    sale_currency: {
                        type: 'string',
                        required: true,
                        message: 'Please enter a currency for the sale.'
                    },
                    Notes: {
                        type: 'string',
                        required: false,
                        message: 'Please enter a description for the sale.'
                    },
                };

                const validator = new Schema(desc);
                return validator.validate(data);
            },
            getData(){
                return {
                    short_name: this.$('#sale_name').value,
                    date_closing: dayjs(this.$('#sale_date_closing').value).toDate(),
                    date_contact: dayjs(this.$('#sale_date_contract').value).toDate(),
                    sale_amount: parseFloat( this.$('#sale_amount').value ),
                    sale_commission: parseFloat( this.$('#sale_commission').value ),
                    sale_commission_percentage: parseFloat( this.$('#sale_commission_percentage').value ),
                    sale_currency: this.$('#sale_currency').value,
                    Notes: this.$('#sale_description').value,
                };
            },
            onSave(){
                console.log('onSave');

                const data = this.getData();

                if(this.sale_id){
                    data.ID = this.sale_id;
                }

                this.verify(data).then((data)=>{
                       console.log('data', data);

                    PubSub.publish('model.sale.create.save', data);

                    this.sendServer(data);
                }).catch((err)=>{
                    if(err && err.errors){
                        this.showFormErrors(err.errors);
                        console.log(err.errors);
                    }else{
                        console.log(err);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong! ' + err.message,
                        });
                    }
                });

            },
            sendServer(data){
                return api_post('/commission/sale', data).then(res=>{
                    PubSub.publish('model.sale.create.done', res.data);

                    console.log(res);

                    if(res.data.data.ID && this.commission_id){
                        return api_post('/commission/sale/link',{sale_id:res.data.data.ID, commission_id:this.commission_id}).then(res=>{
                            PubSub.publish('model.sale.link.done', {sale: res.data, commission_id: this.commission_id});
                            this.onHide();
                        });
                    }else if(res.data.data.ID && this.sale_id) {
                        PubSub.publish('model.sale.update', {sale: res.data, sale_id: this.sale_id});
                        this.onHide();
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Sale Could not link to commission or other error',
                            text: `The sale has not been created. ${res.data.data.ID} ${this.commission_id}`,
                        });
                        this.onHide();
                    }


                });
            },
        }
    </script>

</modal-sale-create>
