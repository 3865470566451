<sale-list>

    <div class="row mb-3">
        <div class="col">
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark-sidebar">

                <div class="container-fluid">
                    <!-- span class="navbar-brand mb-0 h1">Comm. Reports</span -->
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <!-- li class="nav-item ">
                                <select class="form-select-sm me-2 staff_width" data-filter="for_id" onclick="{onFilter}">
                                    <option value="">All Agents</option>
                                    <option value="{s.id}" each="{s in state.staff}">{s.name_display}</option>
                                </select>
                            </li -->


                            <li><label class="form-label mx-2 text-white mt-1 mb-0">Sort:</label></li>

                            <li class="nav-item ">
                                <div class="btn-group" role="group" aria-label="Basic example" onclick="{onQuickSort}">
                                    <button type="button"
                                            class="btn btn-sm  {cn({'btn-outline-light':searchParams.sort!='contract','btn-warning':searchParams.sort=='contract'})}"
                                            data-sort="contract">
                                        Contract
                                        <span if="{searchParams.sort=='contract'}">
                                            <iconify-icon inline icon="material-symbols:keyboard-arrow-up-rounded" if="{searchParams.sortDir=='desc'}"></iconify-icon>
                                            <iconify-icon inline icon="material-symbols:keyboard-arrow-down-rounded" if="{searchParams.sortDir=='asc'}"></iconify-icon>
                                        </span>
                                    </button>
                                    <button type="button"
                                            class="btn btn-sm  {cn({'btn-outline-light':searchParams.sort!='closing','btn-warning':searchParams.sort=='closing'})}"
                                            data-sort="closing">
                                        Closing
                                        <span if="{searchParams.sort=='closing'}">
                                            <iconify-icon inline icon="material-symbols:keyboard-arrow-up-rounded" if="{searchParams.sortDir=='desc'}"></iconify-icon>
                                            <iconify-icon inline icon="material-symbols:keyboard-arrow-down-rounded" if="{searchParams.sortDir=='asc'}"></iconify-icon>
                                        </span>
                                    </button>
                                </div>
                            </li>

                            <li><label class="form-label mx-2 text-white mt-1 mb-0">Date:</label></li>

                            <li class="nav-item">
                                <input type="date" id="date-start" placeholder="start" class="form-control-sm" style="width: 8.5em;" onchange="{onDateChange}">
                                <input type="date" id="date-end" placeholder="end" class="form-control-sm" style="width: 8.5em;" onchange="{onDateChange}">
                            </li>

                            <li>
                                <span class="ms-2" style="cursor: pointer;font-size:1.5em;" onclick="{onExcel}" >
                                    <iconify-icon inline icon="vscode-icons:file-type-excel"></iconify-icon>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>



            </nav>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="card mb-3" each="{s in state.sales}">
                <div class="card-body">
                    <button type="button" class="btn-close" aria-label="Close" style="position:absolute; right:10px; top: 5px;background-color:lightcoral;" data-saleid="{s.ID}" onclick="{onDelete}"></button>
                    <button type="button" class="btn-close" style="background: none; position:absolute; right:40px; top: 5px;background-color:lightblue;" data-saleid="{s.ID}" onclick="{onEdit}">
                        <iconify-icon icon="material-symbols:edit-note" style="font-size: 1.5em;"></iconify-icon>
                    </button>

                    <div class="card-title sale-title">
                        {s.short_name}
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-6">
                                    Closing
                                </div>
                                <div class="col-6">
                                    {fmt.datenice(s.date_closing)}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    Contract
                                </div>
                                <div class="col-6">
                                    {fmt.datenice(s.date_contact)}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-6">
                                    Sale Amount
                                </div>
                                <div class="col-6">
                                    {fmt.currency(s.sale_amount )} {s.sale_currency}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    Sale Commission %
                                </div>
                                <div class="col-6">
                                    {fmt.percent(s.sale_commission_percentage)}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    Sale Commission
                                </div>
                                <div class="col-6">
                                    {fmt.currency(s.sale_commission)} {s.sale_currency}
                                </div>
                            </div>
                        </div>
                    </div>

                    <table class="table table-sm mt-2">
                        <tr each="{r in s.reports}" data-cid="{r.ID}">
                            <td class="lowkey id" style="width: 3em;">{r.ID}</td>
                            <td>{r.staff.name_display}</td>
                            <td style="width: 5em;color:#888;">{fmt.percent( r.commission_paid_percent )}</td>
                            <td style="width: 5em;">{fmt.percent( r.commission_paid_percent_real )}</td>
                            <td style="width: 12em;">{fmt.currency( r.commission_paid )}</td>
                            <td class="text-right w-button-1" style="width: 5em;">
                                <button class="btn btn-secondary btn-sm me-2" onclick="{onView}">
                                    <iconify-icon inline icon="mdi:eye"></iconify-icon>
                                </button>
                            </td>
                        </tr>
                        <tr class="bg-light">
                            <td></td>
                            <td>Realty SMA</td>
                            <td style="width: 5em;color:#ccc;">{fmt.percent(s.realty_percent)}</td>
                            <td>{fmt.percent(s.total_percent_real)}</td>
                            <td class="total_paid">{fmt.currency(s.realty_paid)}</td>
                            <td></td>
                        </tr>
                    </table>

                </div>
            </div>
        </div>
    </div>

    <modal-sale-create />

    <style>
        .sale-title {
            font-size: 1.2rem;
            font-weight: bold;
        }

        .total_paid {
            font-weight: bold;
        }
    </style>

    <script>

        import { api_get,api_delete } from '../../lib/api.js';
        import { router } from '@riotjs/route';
        import Swal from "sweetalert2";
        import ModalSaleCreate from '../modals/modal-sale-create.riot';

        export default {
            components: {
                ModalSaleCreate,
            },
            state: {
                sales: [],
            },
            onBeforeMount() {
                this.searchParams = {
                    sort: "",
                    sortDir: "asc",

                };
            },
            onMounted() {
                console.log('SaleList mounted');
                this.getSales();

                this.event_sale_edit = PubSub.subscribe('model.sale.update', (msg, data) => {
                    console.log('SaleList: model.sale.update');
                    this.getSales();
                });
            },
            onUnmounted() {
                PubSub.unsubscribe(this.event_sale_edit);
            },
            getSales() {
                return api_get('commission/sale', this.searchParams).then((res) => {
                    console.log(res);
                    if (res.status == 200) {
                        const data = this.processSales(res.data);
                        console.log(data.sales);
                        this.update({sales: data.sales});
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong! Could not load sales.',
                        });
                    }
                });
            },
            processSales(data) {
                const staff = {};
                data.staff.forEach(s=>{
                    staff[s.id] = s;
                })

                data.sales.forEach(s => {

                    let total_percent = 0;
                    let total_paid = 0;
                    let total_percent_real = 0;

                    if(s.reports && s.reports.length){
                        s.reports.forEach(r=>{
                            r.staff = staff[r.for_id] || {};

                            if(r.splits.length>0){
                                const split = r.splits[0];
                                r.commission_paid = split.amount - (split.expense || 0);
                                r.commission_paid_percent = split.percentage;
                            }else{
                                r.commission_paid = r.commission_agent_total;
                                r.commission_paid_percent = r.commission_agent;
                            }

                            total_percent += r.commission_paid_percent;
                            total_paid += r.commission_paid;
                        });

                        s.reports.forEach(r=>{
                            if(r.commission_paid != 0 && s.sale_commission!=0){
                                r.commission_paid_percent_real = Math.round((r.commission_paid / s.sale_commission) * 100);
                            }else{
                                r.commission_paid_percent_real = 0;
                            }

                        });
                    }



                    s.total_percent = total_percent;
                    s.total_paid = total_paid;


                    s.realty_percent = 100 - total_percent;
                    s.realty_paid = s.sale_commission - total_paid;

                    if(s.realty_paid != 0 && total_paid!=0 && s.sale_commission!=0){
                        total_percent_real = (s.realty_paid / s.sale_commission) * 100;
                    }

                    s.total_percent_real = Math.round(total_percent_real);

                });

                return data;
            },
            onView(e){
                const id = e.target.closest('tr').dataset.cid;
                console.log(id);
                router.push(`/commission/${id}`);
            },
            onQuickSort(e){
                console.log("Quick Sort", e.target.dataset.sort);
                if(this.searchParams.sort && this.searchParams.sort == e.target.dataset.sort){

                    if(this.searchParams.sortDir == 'desc'){
                        this.searchParams.sortDir = 'asc';
                    }else{
                        delete this.searchParams.sort;
                        delete this.searchParams.sortDir;
                    }


                }else{
                    this.searchParams.sort = e.target.dataset.sort;
                    this.searchParams.sortDir = 'desc';
                }

                this.getSales()
            },
            onDateChange(e){
                console.log("Date Change", e.target.dataset.date);

                const date_start = this.$('#date-start').value;
                const date_end = this.$('#date-end').value;

                this.searchParams.date_start = date_start;
                this.searchParams.date_end = date_end;

                this.getSales()
            },
            onEdit(e){
                const id = e.target.closest('button').dataset.saleid;
                console.log("edit sale",id,e.target);
                console.log("Open Dialog");
                PubSub.publish('model.sale.create.show', {
                    sale:this.state.sales.find(s=>s.ID == id)
                });
            },
            onDelete(e){
                const id = e.target.dataset.saleid;
                console.log("delete sale",id,e.target);
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You are deleting a sale with linked commission reports. This cannot be undone.",
                    icon: 'warning',
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        api_delete(`commission/sale/`+id).then((res) => {
                            if (res.status == 200) {
                                Swal.fire(
                                    'Deleted!',
                                    'Sale has been deleted.',
                                    'success'
                                );
                                this.getSales();
                            }else{
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Something went wrong! Could not delete sale.',
                                });
                            }
                        });
                    }
                });
            },
            onExcel(){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text:  'This feature is not yet available. Needs special formatting.',
                })
            }
        }

    </script>


</sale-list>
