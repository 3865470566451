<step-display>

    <div class="row gx-3">
        <div class="col" each="{s in state.steps}">
            <div style="{getStyle(s.step)}"></div>
        </div>
    </div>

    <script>
        export default {
            state:{
                steps: 1,
                current: 1
            },
            onBeforeMount(props){
                console.log("STEP DISPLAY");
                if(props.steps){
                    this.state.steps = []
                    const stepnum = parseInt(props.steps);
                    if(isNaN(stepnum)) return console.error('Invalid step number');

                    for(let x=1;x<=stepnum;x++){
                        this.state.steps.push({
                            step: x,
                        });
                    }
                }
                if(props.current){
                    this.state.current = props.current;
                }
            },
            onMounted(){
                //nothing here
            },
            getStyle(step){
                if(step==this.state.current) {
                    return "background-color: #007bff; height: 5px; border-radius: 5px;";
                }else{
                    return "background-color: #e9ecef; height: 5px; border-radius: 5px;";
                }
            }
        }
    </script>

</step-display>
