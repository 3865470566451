<commission-list>

    <div class="row">
        <div class="col">
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark-sidebar">

                <div class="container-fluid">
                    <!-- span class="navbar-brand mb-0 h1">Comm. Reports</span -->
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <li class="nav-item ">
                                <select class="form-select-sm me-2 staff_width" data-filter="for_id" onclick="{onFilter}">
                                    <option value="">All Agents</option>
                                    <option value="{s.id}" each="{s in state.staff}">{s.name_display}</option>
                                </select>
                            </li>

                            <li class="nav-item ">
                                <div class="btn-group me-2" role="group" aria-label="Basic example" onclick="{onStatusClick}">
                                    <button type="button"
                                            class="btn btn-sm {cn({'btn-outline-light':searchParams.status!='pending','btn-warning':searchParams.status=='pending'})}"
                                            data-status="pending">Pending</button>
                                    <button type="button"
                                            class="btn btn-sm {cn({'btn-outline-light':searchParams.status!='approved','btn-warning':searchParams.status=='approved'})}"
                                            data-status="approved">Approved</button>
                                    <button type="button"
                                            class="btn btn-sm {cn({'btn-outline-light':searchParams.status!='rejected','btn-warning':searchParams.status=='rejected'})}"
                                            data-status="rejected">Rejected</button>
                                </div>
                            </li>

                            <li class="nav-item ">
                                <div class="btn-group me-2" role="group" aria-label="Basic example" onclick="{onType}">
                                    <button type="button"
                                            class="btn btn-sm  {cn({'btn-outline-light':searchParams.type!='sale','btn-warning':searchParams.type=='sale'})}"
                                            data-type="sale">Sales</button>
                                    <button type="button"
                                            class="btn btn-sm  {cn({'btn-outline-light':searchParams.type!='rental','btn-warning':searchParams.type=='rental'})}"
                                            data-type="rental">Rentals</button>
                                </div>
                            </li>

                            <li><label class="form-label mx-2 text-white mt-1 mb-0">Sort:</label></li>

                            <li class="nav-item ">
                                <div class="btn-group" role="group" aria-label="Basic example" onclick="{onQuickSort}">
                                    <button type="button"
                                            class="btn btn-sm  {cn({'btn-outline-light':searchParams.sort!='contract','btn-warning':searchParams.sort=='contract'})}"
                                            data-sort="contract">
                                        Contract
                                        <span if="{searchParams.sort=='contract'}">
                                            <iconify-icon inline icon="material-symbols:keyboard-arrow-up-rounded" if="{searchParams.sortDir=='desc'}"></iconify-icon>
                                            <iconify-icon inline icon="material-symbols:keyboard-arrow-down-rounded" if="{searchParams.sortDir=='asc'}"></iconify-icon>
                                        </span>
                                    </button>
                                    <button type="button"
                                            class="btn btn-sm  {cn({'btn-outline-light':searchParams.sort!='closing','btn-warning':searchParams.sort=='closing'})}"
                                            data-sort="closing">
                                        Closing
                                        <span if="{searchParams.sort=='closing'}">
                                            <iconify-icon inline icon="material-symbols:keyboard-arrow-up-rounded" if="{searchParams.sortDir=='desc'}"></iconify-icon>
                                            <iconify-icon inline icon="material-symbols:keyboard-arrow-down-rounded" if="{searchParams.sortDir=='asc'}"></iconify-icon>
                                        </span>
                                    </button>
                                </div>
                            </li>

                            <li><label class="form-label mx-2 text-white mt-1 mb-0">Date:</label></li>

                            <li class="nav-item">
                                <input type="date" id="date-start" placeholder="start" class="form-control-sm" style="width: 8.5em;" onchange="{onDateChange}">
                                <input type="date" id="date-end" placeholder="end" class="form-control-sm" style="width: 8.5em;" onchange="{onDateChange}">
                            </li>

                            <li>
                                <span class="ms-2" style="cursor: pointer;font-size:1.5em;" onclick="{onExcel}" >
                                    <iconify-icon inline icon="vscode-icons:file-type-excel"></iconify-icon>
                                </span>
                            </li>
                        </ul>

                    </div>
                </div>



            </nav>
        </div>
    </div>

    <div class="row">

        <div class="col">


            <table class="table">

                <thead>
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col" >Who</th>
                        <th scope="col">Type</th>
                        <th scope="col">Status</th>
                        <!-- th scope="col">Created</th -->
                        <th scope="col">Contract</th>
                        <th scope="col">Closing</th>
                        <th scope="col">Sale?</th>
                        <th scope="col">Realty Comm.</th>
                        <th scope="col">Total</th>
                        <th scope="col">Curr.</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr each="{c in state.commissions}" scope="row" data-cid="{c.ID}">
                        <td class="text-grey">{c.ID}</td>
                        <td>
                            <span if="{!c.staff}">{c.for_id}</span>
                            <span if="{c.staff}">{c.staff.name_display}</span>
                        </td>
                        <td>{c.commission_type}</td>
                        <td class="{c.status}">{c.status}</td>
                        <!-- td>{fmt.datenice(c.create_at)}</td -->
                        <td>{fmt.datenice(c.contract_date)}</td>
                        <td>{fmt.datenice(c.closing_date)}</td>
                        <td>
                            <span if="{c.sale_id}">
                                <iconify-icon inline icon="emojione:white-heavy-check-mark"></iconify-icon>
                            </span>
                        </td>
                        <td>{currency(c.commission_agency_total)}</td>

                        <td class="curr_{c.commission_currency}">
                            {currency(c.commission_price)}
                        </td>
                        <td>
                            <span if="{c.commission_currency == 'MXN'}" >
                                <iconify-icon inline icon="emojione-v1:flag-for-mexico"></iconify-icon>
                            </span>
                            <span if="{c.commission_currency == 'USD'}" >
                                <iconify-icon inline icon="emojione-v1:flag-for-united-states"></iconify-icon>
                            </span>
                        </td>
                        <td>
                            <!--button class="btn btn-primary btn-sm me-2">
                                <iconify-icon inline icon="material-symbols:edit-document"></iconify-icon>
                            </button -->
                            <button class="btn btn-secondary btn-sm me-2" onclick="{onView}">
                                <iconify-icon inline icon="mdi:eye"></iconify-icon>
                            </button>
                            <!-- button class="btn btn-success btn-sm">
                                <iconify-icon inline icon="ph:currency-circle-dollar-light"></iconify-icon>
                            </button -->
                        </td>
                    </tr>
                </tbody>

            </table>

        </div>

    </div>

    <style>
        .text-grey {
            color: #6c757d;
        }
        td.pending {
          background-color: wheat;
        }
        .staff_width {
            width: 14em;
        }
        .curr_MXN  {
            color: darkgreen;
            font-weight: bold;
        }
        .curr_USD  {
            color: darkred;
            font-weight: bold;
        }
    </style>

    <script>

        import { api_get } from '../../lib/api.js';
        import currency from 'currency.js';
        import dayjs from 'dayjs';
        import { router } from '@riotjs/route';
        import {setCacheItem, getCacheItem} from '../../lib/cache.js';
        import Swal from 'sweetalert2';
        import {ExelCreator} from "../../lib/excel2.js";

        export default {
            components: {

            },
            state: {
                commissions: [],
                staff:[]
            },
            onBeforeMount() {
                console.log('Commission List mounted')
                this.searchParams = {};
            },
            onMounted() {
                this.loadCommissions().then(() => {
                    this.loadStaff();
                });
            },
            currency(value){
                return currency(value).format();
            },
            datef(d){
                return dayjs(d).format('MM/DD/YYYY');
            },
            loadCommissions(){
                return api_get('commission/', this.searchParams).then((res)=>{
                    console.log(res);
                    if(res.status == 200){
                        console.log("Updateing commissions");
                        this.update({
                            commissions: res.data
                        });
                    }
                });
            },
            loadStaff(){

                const staff = getCacheItem('staff');

                console.log(staff, "staff cached");

                if(staff){
                    this.update({
                        staff: staff
                    });
                    return true;
                }

                return api_get('staff/').then((res)=>{
                    console.log(res);
                    if(res.status == 200){
                        console.log("updating staff");
                        const staff = res.data.filter(s=>s.active=='yes');
                        this.update({
                            staff: staff
                        });
                        setCacheItem('staff', staff, 120);
                    }
                });
            },
            onView(e){
                const id = e.target.closest('tr').dataset.cid;
                console.log(id);
                router.push(`/commission/${id}`);
            },
            onFilter(e){
                const p = e.target.dataset.filter;
                this.searchParams[p] = e.target.value;
                this.loadCommissions();
            },
            onStatusClick(e){
                console.log("Status Click", e.target.dataset.status);
                if(this.searchParams.status && this.searchParams.status == e.target.dataset.status){
                    delete this.searchParams.status;
                }else{
                    this.searchParams.status = e.target.dataset.status;
                }

                this.loadCommissions()
            },
            onType(e){
                console.log("Status Click", e.target.dataset.type);
                if(this.searchParams.type && this.searchParams.type == e.target.dataset.type){
                    delete this.searchParams.type;
                }else{
                    this.searchParams.type = e.target.dataset.type;
                }

                this.loadCommissions()
            },
            onQuickSort(e){
                console.log("Quick Sort", e.target.dataset.sort);
                if(this.searchParams.sort && this.searchParams.sort == e.target.dataset.sort){

                    if(this.searchParams.sortDir == 'desc'){
                        this.searchParams.sortDir = 'asc';
                    }else{
                        delete this.searchParams.sort;
                        delete this.searchParams.sortDir;
                    }


                }else{
                    this.searchParams.sort = e.target.dataset.sort;
                    this.searchParams.sortDir = 'desc';
                }

                this.loadCommissions()
            },
            onDateChange(e){
                console.log("Date Change", e.target.dataset.date);

                const date_start = this.$('#date-start').value;
                const date_end = this.$('#date-end').value;

                this.searchParams.date_start = date_start;
                this.searchParams.date_end = date_end;

                this.loadCommissions()
            },
            onExcel(){
                if(this.state.commissions) {
                    const ex = new ExelCreator();
                    ex.addSheet("Commissions", this.state.commissions);
                    ex.out("commissions.xlsx");
                }
            }
        }

    </script>
</commission-list>
