/* Riot v7.1.0, @license MIT */
import $ from '../node_modules/bianco.query/index.next.js';
import { mountComponent } from '../core/mount-component.js';

/**
 * Mounting function that will work only for the components that were globally registered
 * @param   {string|HTMLElement} selector - query for the selection or a DOM element
 * @param   {Object} initialProps - the initial component properties
 * @param   {string} name - optional component name
 * @returns {Array} list of riot components
 */
function mount(selector, initialProps, name) {
  return $(selector).map(element => mountComponent(element, initialProps, name));
}

export { mount };
