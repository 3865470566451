
import { api_post } from "./api";


function SetCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}
export function loginlink(user, staff, name, hash) {
    return api_post("auth/login/link", { user, staff, name, hash }).then((res) => {

        if(res.status != 200){
          return false;
        }

        if(res.data.success && res.data.token){
          SetCookie('token', res.data.token, 7);
          SetCookie('user_username', res.data.user.username, 7)
          SetCookie('user_id', res.data.user.id);
          SetCookie('staff_id',res.data.user.link_staff_id);

          return res.data.user;
        }else{
          return false;
        }
    });
}
