<start-agent>


    <agent-commissions-list />


    <script>

        import AgentCommissionsList from '../../commission/agent-commissions-list.riot';
        //import lazy from '@riotjs/lazy'

        export default {
            components: {
                AgentCommissionsList
            },
            onMounted() {
                console.log('Start Form mounted')
            }
        }

    </script>
</start-agent>
