/* Riot v7.1.0, @license MIT */
export { register } from './api/register.js';
export { unregister } from './api/unregister.js';
export { mount } from './api/mount.js';
export { unmount } from './api/unmount.js';
export { install } from './api/install.js';
export { uninstall } from './api/uninstall.js';
export { component } from './api/component.js';
export { pure } from './api/pure.js';
export { withTypes } from './api/with-types.js';
export { version } from './api/version.js';
export { __ } from './api/__.js';
