/* Riot v7.1.0, @license MIT */
import { isFunction } from '../node_modules/@riotjs/util/checks.js';
import { IS_PURE_SYMBOL } from '../node_modules/@riotjs/util/constants.js';
import { panic } from '../node_modules/@riotjs/util/misc.js';

/**
 * Lift a riot component Interface into a pure riot object
 * @param   {Function} func - RiotPureComponent factory function
 * @returns {Function} the lifted original function received as argument
 */
function pure(func) {
  if (!isFunction(func)) panic('riot.pure accepts only arguments of type "function"');
  func[IS_PURE_SYMBOL] = true;
  return func;
}

export { pure };
