const _temp0 = require("../app/app.riot");

const _temp1 = require("../login/login.riot");

const _temp2 = require("../login-link/login-link.riot");

const _temp3 = require("../my-hello/my-hello.riot");

const _temp4 = require("../start-agent/start-agent.riot");

const _temp5 = require("../start-form/start-form.riot");

module.exports = {
  "app":   {
    "app": _temp0
  },
  "login":   {
    "login": _temp1
  },
  "login-link":   {
    "login-link": _temp2
  },
  "my-hello":   {
    "my-hello": _temp3
  },
  "start-agent":   {
    "start-agent": _temp4
  },
  "start-form":   {
    "start-form": _temp5
  }
}