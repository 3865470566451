<commission-view>

    <!-- div class="row">
        <div class="col">
            <nav class="navbar navbar-expand-lg navbar-dark nav-grey">

                <div class="container-fluid">
                    <span class="navbar-brand mb-0 h1">Comm. Reports</span>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                                   data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown link
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>


            </nav>
        </div>
    </div -->

    <div class="row pt-2" if="{state.commission}">

        <div class="col d-flex align-items-stretch">
            <div class="card flex-fill">

                <!----  Start of Commission Details ---->

                <div class="card-body" if="{state.staff}">
                    <div class="card-title">
                        <div class="row">
                            <div class="col-auto">
                                <span class="badge me-2 {cn.approval(state.commission.status)}">
                                    {state.commission.status}: {fmt.datenice(state.commission.status_at)}
                                </span>

                                <span class="badge bg-secondary me-2">{fmt.datenice(state.commission.status_at)}</span>

                                <span class="badge {cn.type(state.commission.commission_type)}">
                                    {state.commission.commission_type}
                                </span>

                                <h2 class="m-0 p-0">{state.staff.name_display} </h2>
                                <h5 class="m-0 p-0 text-black-50">{state.commission.property_address}
                                    <span class="ms-2" style="cursor: pointer;" onclick="{onExcel}">
                                        <iconify-icon inline icon="vscode-icons:file-type-excel"></iconify-icon>
                                    </span>
                                </h5>

                            </div>
                            <div class="col">
                                <img class="border border-1 rounded-circle shadow float-end avatar-info"
                                     src="https://sanmiguelrealestate.com/real-estate/about-us/picture/thumb2/{state.staff.id}"/>
                            </div>
                        </div>

                    </div>
                    <p>
                    <table class="table">
                        <tr if="{state.commission.sale_id}" class="row-sale">
                            <td>
                                <strong>Linked Sale:</strong>
                            </td>
                            <td>
                                <span if="{!is.empty(state.sale)}">{state.sale.short_name}</span>
                            </td>
                            <td class="text-right">
                                <a class="text-black-50" href="/sales">
                                    <iconify-icon inline icon="mdi:eye"></iconify-icon>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Under Contract:</strong> <div class="float-end">{fmt.datenice(state.commission.contract_date)}</div></td>
                            <td colspan="2"><strong>Closing:</strong> <div class="float-end">{fmt.datenice(state.commission.closing_date)}</div></td>
                        </tr>
                        <tr>
                            <td><strong class="me-2">{fmt.capitalize(state.commission.commission_type)}:</strong></td>
                            <td>{fmt.currency(state.commission.commission_price)} {state.commission.commission_currency}</td>
                            <td>
                                <span if="{state.commission.commission_currency == 'MXN'}" >
                                    <iconify-icon inline icon="emojione-v1:flag-for-mexico"></iconify-icon>
                                </span>
                                    <span if="{state.commission.commission_currency == 'USD'}" >
                                    <iconify-icon inline icon="emojione-v1:flag-for-united-states"></iconify-icon>
                                </span>
                            </td>
                        </tr>
                        <tr if="{state.commission.commission_type != 'rental'}">
                            <td><strong>Commission Total:</strong></td>
                            <td>{fmt.currency(state.commission.commission_total)} {state.commission.commission_currency}</td>
                            <td>
                                <span if="{state.commission.commission_currency == 'MXN'}" >
                                    <iconify-icon inline icon="emojione-v1:flag-for-mexico"></iconify-icon>
                                </span>
                                <span if="{state.commission.commission_currency == 'USD'}" >
                                    <iconify-icon inline icon="emojione-v1:flag-for-united-states"></iconify-icon>
                                </span>
                            </td>
                        </tr>
                        <tr if="{state.commission.commission_type != 'rental'}">
                            <td><strong class="me-2">Full Sale Commission:</strong></td>
                            <td>{fmt.percent(state.commission.commission)}</td>
                            <td></td>
                        </tr>
                        <tr if="{state.commission.commission_type == 'rental'}">
                            <td><strong class="me-2">Rental Commission Type</strong></td>
                            <td>{fmt.rentaltype(state.commission.rental_contract_type)}</td>
                            <td></td>
                        </tr>
                        <tr if="{state.commission.commission_type != 'rental'}">
                            <td><strong>Realty Commission: </strong></td>
                            <td>
                                {fmt.percent(state.commission.commission_agency)}

                            </td>
                            <td>
                                <span if="{state.commission.comission_agency == '' || state.commission.comission_agency == '0'}">
                                            <span class="badge bg-warning ms-3">Incomplete</span>
                                        </span>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Realty Side: </strong></td>
                            <td>
                                {fmt.currency(state.commission.commission_agency_total)}

                            </td>
                            <td>
                                <span if="{state.commission.comission_agency_total == '' || state.commission.comission_agency_total == '0'}">
                                            <span class="badge bg-warning ms-3">Incomplete</span>
                                        </span>
                            </td>
                        </tr>
                        <tr if="{!is.empty(state.commission.other_agency)}">
                            <td><strong class="me-2">Other Agency</strong></td>
                            <td>{state.commission.other_agency}</td>
                            <td></td>
                        </tr>
                        <tr if="{!is.empty(state.commission.other_agency)}">
                            <td><strong class="me-2">Other Agency Commission</strong></td>
                            <td>{fmt.percent(state.commission.commission-state.commission.commission_agency)}</td>
                            <td></td>
                        </tr>

                    </table>

                    </p>

                </div>

                <!----  End of Commission Details ---->

            </div>
        </div>

        <!-- Property Information -->
        <div class="col-auto d-flex align-items-stretch" >
            <property-card property-ref="{state.commission.property_ref}" if="{!is.empty(state.commission) && !is.empty(state.commission.property_ref)}" />
        </div>

    </div>

    <div class="row mt-3" if="{!is.empty(state.commission.comments)}">
        <div class="col d-flex align-items-lg-stretch">
            <div class="card flex-fill">
                <div class="card-body">
                    {state.commission.comments}
                </div>
            </div>
        </div>
    </div>


    <div class="row mt-3">

        <div class="col d-flex align-items-stretch">



            <div class="card flex-fill">

                <!-- Agents Breakdown -->
                <div class="card-body">
                    <div class="card-title">
                        Agent's Commission Breakdown
                    </div>
                    <span if="{hasNotAgentBreakdown()}">
                        <span class="badge bg-warning ms-3">Incomplete</span>
                    </span>
                </div>

                <!-- Approved Breakdown -->
                <table class="table">
                    <tr>
                        <td>Agent Commission</td>
                        <td>{fmt.percent(state.commission.commission_agent)}</td>
                    </tr>
                    <tr>
                        <td>Agent Total</td>
                        <td>{fmt.currency(state.commission.commission_agent_total)}</td>
                    </tr>
                    <tr>
                        <td>Half Expenses</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Total Commission</td>
                        <td>0</td>
                    </tr>
                </table>

            </div>
        </div>

        <div class="col d-flex align-items-stretch">
            <div class="card flex-fill">
                <div class="card-body">
                    <div class="card-title">
                        Approved Commission Breakdown
                    </div>

                    <table class="table" if="{!is.empty(state.split)}">
                        <tr>
                            <td>Agent Commission</td>
                            <td>{fmt.percent(state.split.percentage)}</td>
                        </tr>
                        <tr>
                            <td>Side</td>
                            <td>{fmt.capitalize(state.split.buyer_seller)}</td>
                        </tr>
                        <tr>
                            <td>Agent Total</td>
                            <td>{fmt.currency(state.split.amount)}</td>
                        </tr>
                        <tr>
                            <td>Half Expenses</td>
                            <td>{fmt.currency(state.split.expense)}</td>
                        </tr>
                        <tr>
                            <td>Agency Credit</td>
                            <td>{fmt.currency(state.split.agency_credit)}</td>
                        </tr>
                    </table>

                    <button class="btn btn-dark me-2" onclick="{onAddCommission}" if="{is.empty(state.split)}">Add</button>
                    <button class="btn btn-dark" onclick="{onDeleteCommission}" if="{!is.empty(state.split)}">Delete</button>
                </div>
            </div>
        </div>

        <div class="col-auto d-flex align-items-stretch">

            <div class="card" style="width: 18rem;">
                <div class="card-body">
                    <div class="card-title">
                        Administration
                    </div>

                    <div class="d-grid gap-2">

                        <div class="dropdown" onclick="{onApproval}">
                            <button class="btn btn-dark w-100 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>Approve</span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                                <li><a class="dropdown-item" href="#" data-approval="approved">Approve</a></li>
                                <li><a class="dropdown-item" href="#" data-approval="rejected">Reject</a></li>
                                <li><a class="dropdown-item" href="#" data-approval="pending">Pending</a></li>
                            </ul>
                        </div>

                        <div class="dropdown" onclick="{onSale}">
                            <button class="btn btn-dark w-100 dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                Sale
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2" >
                                <li><a class="dropdown-item" href="#" data-sale="create" if="{is.empty(state.commission.sale_id)}">Create Sale</a></li>
                                <li><a class="dropdown-item" href="#" data-sale="link" if="{is.empty(state.commission.sale_id)}">Link Sale</a></li>
                                <li><a class="dropdown-item" href="#" data-sale="remove" if="{!is.empty(state.commission.sale_id)}">Remove Link</a></li>
                            </ul>
                        </div>

                        <button class="btn btn-outline-secondary">Email</button>
                        <button class="btn btn-primary" onclick="{onEdit}">Edit</button>
                        <button class="btn btn-danger" onclick="{onDelete}">Delete</button>

                    </div>

                </div>
            </div>
        </div>

    </div>

    <div class="row mt-3">

        <div class="col d-flex align-items-stretch">

            <div class="card flex-fill" if="{state.commission.client_needs_factura == 'yes'}">
                <div class="card-body">
                    <div class="card-title">
                        Client Needs Factura <iconify-icon inline icon="ic:round-warning" style="color:#ffc107!important;"></iconify-icon>
                    </div>

                    <p>
                        <table class="table">
                            <tr>
                                <td><strong>Client Name:</strong></td>
                                <td>{state.commission.client_name_legal}</td>
                            </tr>
                            <tr>
                                <td><strong>Client RFC:</strong></td>
                                <td>{state.commission.client_rfc}</td>
                            </tr>
                            <tr>
                                <td><strong>Client Address:</strong></td>
                                <td>
                                    <pre>
                                        {state.commission.client_address_street} <br>
                                        {state.commission.client_address_city}, {state.commission.client_address_state} {state.commission.client_address_postal}
                                    </pre>
                                </td>
                            </tr>

                        </table>
                    </p>

                </div>
            </div>

        </div>

    </div>

    <modal-sale-create />
    <modal-commission-split />
    <modal-sale-link />

    <style>

        .card-title {
            font-size: 1.2rem;
        }

        .badgez {
            font-size: 1rem;
        }

        .nav-grey {
            background-color: dimgrey;
            border-radius: 0.5em;
            margin-bottom: 1em;
        }

        .avatar-info {
            width: 7em;
            height: 7em;
        }

        .row-sale {
            background-color: wheat;
        }
    </style>

    <script>

        import {api_get, api_post, api_delete} from "../../lib/api";
        import CommissionViewSummary from "./commission-view-summary";
        import PropertyCard from "../controls/property-card";
        import ModalSaleCreate from "../modals/modal-sale-create";
        import ModalCommissionSplit from "../modals/modal-commission-split";
        import ModalSaleLink from "../modals/modal-sale-link";
        import {ExelCreator} from "../../lib/excel2.js";

        import {router} from "@riotjs/route";
        import Swal from "sweetalert2";

        import PubSub from "pubsub-js";

        export default {
            components: {
                PropertyCard,
                ModalSaleCreate,
                ModalCommissionSplit,
                ModalSaleLink,
                CommissionViewSummary
            },
            state: {
                commission: {},
                split: {},
                sale: null
            },
            onBeforeMount() {
                console.log('Commission View Mounted');
                console.log(this.props.cid);

                this.cn.type = (type) => {
                    if (type == 'sale') {
                        return 'bg-success';
                    } else {
                        return 'bg-primary';
                    }
                }

                this.cn.approval = (approval) => {
                    switch (approval) {
                        case 'approval':
                            return 'bg-success';
                        case 'reject':
                            return 'bg-danger';
                        case 'pending':
                            return 'bg-warning';
                        default:
                            return 'bg-secondary';
                    }
                }
            },
            onMounted() {
                this.loadCommission();
                this.loadApprovedCommission();


                PubSub.subscribe('model.commission.split.saved', (msg, data) => {
                    console.log('commission-split', data);
                    setTimeout(() => {
                        this.loadCommission();
                        this.loadApprovedCommission();
                    }, 500);
                });
            },
            loadCommission() {
                return api_get(`commission/${this.props.cid}`).then((res) => {
                    console.log("loading", res);
                    if (res.status == 200) {

                        this.update({
                            commission: res.data,
                            staff: res.data.staff
                        });

                        if(res.data.sale_id) {
                            this.loadSale(res.data.sale_id);
                        }
                    }
                });
            },
            loadSale(id){
                console.log("loading sale", id);
                return api_get('commission/sale/'+id).then((res) => {
                    console.log("Recieved Sale", res);
                    if (res.status == 200) {

                        this.update({
                            sale: res.data
                        });
                        //console.log(this.state.staff);
                    }else{
                        console.warn("Could not load sale");
                    }
                });
            },
            loadApprovedCommission(){
                console.log("Loading Approved Commission");
                return api_get(`commission/split/${this.props.cid}`).then(res=>{
                    if(res.status == 200 && res.data.success!=false){
                        this.update({
                            split: res.data
                        });
                        console.log(this.state.splits);
                    }else{
                        this.update({
                            split: null
                        });
                        console.log("No approved commission");
                        if(res.status==200) console.log(res.data);
                    }
                });
            },
            hasNotAgentBreakdown() {
                const c = this.state.commission;
                return c.commission_agent == '' || c.commission_agent == '0' || c.commission_agent == 0
                        || c.commission_agent_total == '' || c.commission_agent_total == '0'
                        || c.commission_agent_total == 0;
            },
            onEdit() {
                router.push(`/commission/edit/${this.props.cid}`);
            },
            onApproval(e){
                e.preventDefault();

                const status = e.target.dataset.approval;
                if(!status) return;

                console.log(status);

                return api_post(`commission/approval/${this.props.cid}/${status}`, {}).then((res) => {
                    console.log(res);
                    if (res.status == 200) {
                        Swal.fire('Success', 'Commission status updated', 'success');
                        this.loadCommission();
                    }
                });
            },
            onDelete(e){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        return api_delete(`commission/${this.props.cid}`, {}).then((res) => {
                            console.log(res);
                            if (res.status == 200) {
                                Swal.fire('Success', 'Commission deleted', 'success');
                                router.push('/commissions');
                            }
                        });
                    }
                });
            },
            onSale(e){
                e.preventDefault();
                const action = e.target.dataset.sale;
                console.log(action);

                if(action == 'create') {
                    console.log("Open Dialog");
                    PubSub.publish('model.sale.create.show', this.state.commission);
                }

                if(action=='link'){
                    console.log("Open Dialog");
                    PubSub.publish('model.sale.link.show', this.state.commission);
                }

            },
            onAddCommission(e){
                e.preventDefault();
                PubSub.publish('model.commission.split.show', this.state.commission);
            },
            onDeleteCommission(e){
                e.preventDefault();

                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        return api_delete(`commission/split/${this.props.cid}`, {}).then((res) => {
                            console.log(res);
                            if (res.status == 200) {
                                Swal.fire('Success', 'Commission deleted', 'success');
                            }else{
                                Swal.fire('Error', 'Commission not deleted', 'error');
                            }
                            setTimeout(()=>{
                                this.loadCommission();
                                this.loadApprovedCommission();
                            }, 500);
                        });
                    }
                });

            },
            onExcel(){

                if(this.state.commission) {
                    const ex = new ExelCreator();
                    ex.addSheet("Commissions", [this.state.commission]);
                    ex.out("export.xlsx");
                }
            }
        }
    </script>

</commission-view>
