<property-card>

    <div class="card" style="width: 18rem;">
        <img src="https://sanmiguelrealestate.com/pictures/property/medium/{state.property.id}" class="card-img-top" alt="">
        <div class="card-body">
            <h5 class="card-title">{state.property.name}</h5>
            <p class="card-text">{trun(state.property.intro)}</p>
            <div class="text-center">
                <a href="https://sanmiguelrealestate.com/real-estate/for-sale/property/{state.property.ref}" target="_blank"
                   class="btn btn-sm btn-info">View</a>
            </div>

        </div>
    </div>

    <script>

        import { api_get } from "../../lib/api";
        import truncate from "truncate";

        export default {
            state: {
                property: {}
            },
            onBeforeMount(props) {
                console.log('Property Before Mounted', props);
                if(props.propertyRef){
                    this.propertyRef = props.propertyRef;
                }
            },
            onMounted() {
                console.log("Property Card Mounted", this.propertyRef);
                this.loadProperty();
            },
            trun(str) {
              return truncate(str, 120);
            },
            loadProperty(){
                return api_get(`property/${this.propertyRef}`).then((res)=>{
                    console.log(res);
                    if(res.status == 200){

                        this.update({
                            property: res.data
                        });
                    }
                });
            }
        }

    </script>

</property-card>
