<input-currency>

    <input type="text" class="w-100 form-control" onkeyup="{ onUpdate }" onblur="{ onUpdate }"
           value="{ props.value }" placeholder="{ props.placeholder }" id="{state.elid}"/>

    <script>

        import currency from "currency.js";
        import PubSub from "pubsub-js";
        export default {
            onBeforeMount(props){
                if(props.name){
                    PubSub.subscribe('input-currency.'+props.name, this.setValue.bind(this));
                }
                if(props.inputId){
                    this.state.elid = props.inputId;
                }else{
                    this.state.elid = this.genId();
                }

                this.hiddenId = false;

            },
            onMounted(props, state){
                if(props.hiddenId){
                    this.hiddenId = props.hiddenId;

                    const $el = document.getElementById(this.hiddenId);

                    if(!$el) return console.error(`Hidden element ${this.hiddenId} was not found`);
                    this._event_hidden = this.onHiddenChanged.bind(this);
                    this._hidden_el = $el;

                    console.log(`-- Setting event hidden ${this.hiddenId}`);
                    $el.addEventListener('change',this._event_hidden);
                }
            },
            onUnmounted(){
                if(this._hidden_el){
                    if(this._event_hidden){
                        this._hidden_el.removeEventListener('change', this._event_hidden);
                    }
                }
            },
            onHiddenChanged(e){
                console.log("Hidden field changed", this.hiddenId, e.target.value);
                const value = e.target.value;
                if(value) this.setValue('',value);
            },
            setHiddenValue(val){
                if(!this.hiddenId) return false;
                const $el = document.getElementById(this.hiddenId);
                if(!$el) return console.error(`Can not set hidden value ${this.hiddenId}`);
                $el.value = val;
            },
            onUpdate(e) {
                this.formatCurrency(e.target)
                this.send(e.target);
                this.setHiddenValue(this.convertNumber(e.target.value))
            },
            onBlur(e) {
                this.formatCurrency(e.target, "blur")
                this.send(e.target);
            },
            setValue(msg, val){
                console.log(msg,val)
                const v = currency(val).format();
                const $el = this.$('#'+this.state.elid);
                console.log(this.state.elid, v);
                $el.value = v;
                $el.dispatchEvent(new Event('change'));
                this.setHiddenValue(this.convertNumber(currency(val).value));
            },
            send(input){
                if(this.props.onupdate) this.props.onupdate(this.props.name, this.convertNumber(input.value));
            },
            convertNumber(n){
                return currency(n).value;
            },
            formatNumber(n) {
                return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            formatCurrency(input, blur) {
                // appends $ to value, validates decimal side
                // and puts cursor back in right position.
                
                // get input value
                var input_val = input.value;
                
                // don't validate empty input
                if (input_val === "") { return; }
                
                // original length
                var original_len = input_val.length;

                // initial caret position 
                var caret_pos = input.selectionStart;
                    
                // check for decimal
                if (input_val.indexOf(".") >= 0) {

                    // get position of first decimal
                    // this prevents multiple decimals from
                    // being entered
                    var decimal_pos = input_val.indexOf(".");

                    // split number by decimal point
                    var left_side = input_val.substring(0, decimal_pos);
                    var right_side = input_val.substring(decimal_pos);

                    // add commas to left side of number
                    left_side = this.formatNumber(left_side);

                    // validate right side
                    right_side = this.formatNumber(right_side);
                    
                    // On blur make sure 2 numbers after decimal
                    if (blur === "blur") {
                    right_side += "00";
                    }
                    
                    // Limit decimal to only 2 digits
                    right_side = right_side.substring(0, 2);

                    // join number by .
                    input_val = "$" + left_side + "." + right_side;

                } else {
                    // no decimal entered
                    // add commas to number
                    // remove all non-digits
                    input_val = this.formatNumber(input_val);
                    input_val = "$" + input_val;
                    
                    // final formatting
                    if (blur === "blur") {
                    input_val += ".00";
                    }
                }
                
                // send updated string to input
                input.value = input_val;

                // put caret back in the right position
                var updated_len = input_val.length;
                caret_pos = updated_len - original_len + caret_pos;
                input.setSelectionRange(caret_pos, caret_pos);
            }
        }

    </script>
</input-currency>
