/* Riot v7.1.0, @license MIT */
import { template as create } from '../node_modules/@riotjs/dom-bindings/dist/esm.dom-bindings.js';
import expressionTypes from '../node_modules/@riotjs/util/expression-types.js';
import bindingTypes from '../node_modules/@riotjs/util/binding-types.js';

/**
 * Factory function to create the component templates only once
 * @param   {Function} template - component template creation function
 * @param   {RiotComponentWrapper} componentWrapper - riot compiler generated object
 * @param   {Function} getChildComponent - getter function to return the children components
 * @returns {TemplateChunk} template chunk object
 */
function componentTemplateFactory(template, componentWrapper, getChildComponent) {
  return template(create, expressionTypes, bindingTypes, getChildComponent);
}

export { componentTemplateFactory };
