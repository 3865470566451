/* Riot v7.1.0, @license MIT */
import compose from '../node_modules/cumpa/index.next.js';
import { createComponentFromWrapper } from '../core/create-component-from-wrapper.js';

/**
 * Helper method to create component without relying on the registered ones
 * @param   {Object} implementation - component implementation
 * @returns {Function} function that will allow you to mount a riot component on a DOM node
 */
function component(implementation) {
  return function (el, props, _temp) {
    let {
      slots,
      attributes,
      parentScope
    } = _temp === void 0 ? {} : _temp;
    return compose(c => c.mount(el, parentScope), c => c({
      props,
      slots,
      attributes
    }), createComponentFromWrapper)(implementation);
  };
}

export { component };
