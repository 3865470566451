<login>
    <section class="vh-100" style="background-color: #f6f9ff;">
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col col-xl-10">
              <div class="card" style="border-radius: 1rem;">
                <div class="row g-0">
                  <div class="col-md-6 col-lg-5 d-none d-md-block">
                    <img src="{state.images.stock1}"
                      alt="login form" class="img-fluid" style="border-radius: 1rem 0 0 1rem;object-fit: cover;" />
                  </div>
                  <div class="col-md-6 col-lg-7 d-flex align-items-center">
                    <div class="card-body p-4 p-lg-5 text-black">
      
                      <form onsubmit="{onLogin}">

                        <div class="d-flex align-items-center mb-3 pb-1">
                            <iconify-icon icon="mdi:home" style="color: #ba3329; font-size: 48px"></iconify-icon>
                            <!-- i class="fas fa-cubes fa-2x me-3" style="color: #ff6219;"></i -->
                            <span class="h1 fw-bold mb-0">Realty Commissions</span>
                        </div>
      
                        <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Sign into your account</h5>
      
                        <div class="form-outline mb-4">
                          <input type="email" id="user" class="form-control form-control-lg" />
                          <label class="form-label" for="email">Username</label>
                        </div>
      
                        <div class="form-outline mb-4">
                          <input type="password" id="pass" class="form-control form-control-lg" />
                          <label class="form-label" for="password">Password</label>
                        </div>
      
                        <div class="pt-1 mb-4">
                          <button class="btn btn-dark btn-lg btn-block" type="submit" onclick="{onLogin}">Login</button>
                        </div>
      
                        <!-- a class="small text-muted" href="#!">Forgot password?</a>
                        <p class="mb-5 pb-lg-2" style="color: #393f81;">Don't have an account? <a href="#!"
                            style="color: #393f81;">Register here</a></p>
                        <a href="#!" class="small text-muted">Terms of use.</a>
                        <a href="#!" class="small text-muted">Privacy policy</a>
                        !-->
                      </form>
      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <script>

        import images from "../../../../assets/img/*.webp";
        import Cookies from 'js-cookie';
        import { api_post } from '../../../lib/api.js';

        export default {
          state: {
            name: 'World',
            images: images
          },
          onBeforeMounted(){
            this.state.token = Cookies.get('token');
          },
          onMounted() {
            console.log('Login mounted');

            if(this.state.token){
              this.checkLogin();
            }else{
              console.log("Not Logged in");
            }

            if(this.query_params.user, this.query_params.username, this.query_params.hash, this.query_params.staff){
              this.remote_login_attempt = true;
              if(this.query_params.redirect) {
                //Todo: Redirect to the redirect url
                window.location = this.query_params.redirect + "?user=" + this.query_params.user + "&username=" + this.query_params.username + "&hash=" + this.query_params.hash + "&staff=" + this.query_params.staff;
              }else{
                window.location = "/dashboard";
              }
            }

          },
          onLogin(e){
            e.preventDefault();
            e.stopPropagation();

            console.log('onLogin');

            const user = this.$('#user').value;
            const pass = this.$('#pass').value;

            this.login(user,pass);
          },
          checkLogin(){
            console.log('checkLogin');
          },
          login(user,pass){
            console.log('login');
            return api_post('auth/login',{
              username: user,
              password: pass
            }).then((res)=>{
              console.log(res);
              if(res.status == 200){
                Cookies.set('token',res.data.token);
                Cookies.set('user_username',res.data.user.username);
                Cookies.set('user_id',res.data.user.id);
                window.location.href = '/';
              }
            });
          }
        }
      </script>
      
</login>
