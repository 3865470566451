import '../settings.js';
import '@riotjs/hot-reload'
import { mount, install } from 'riot'
import registerGlobalComponents from './register-global-components'

import 'bootstrap/dist/css/bootstrap.min.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import 'iconify-icon';
import '../assets/css/style.css';

window.bootstrap = bootstrap;

import dayjs from "dayjs";
import currency from "currency.js";
import jwt_decode from "jwt-decode";

const cookies = document.cookie.split(';').map(item => item.split('=')).reduce((acc, [k, v]) => (acc[k.trim().replace('"', '')] = v) && acc, {});


install((component)=>{
    component._app_ver = '0.0.3';

    const jwt = cookies && cookies.token ? jwt_decode(cookies.token) : false;
    //console.log("JWT", jwt)

    if(jwt && jwt.role){
      component._user = {
        admin: jwt && jwt.role ? true : false,
        username: cookies.user_username,
        id: cookies.user_id
      };
    }else{
      component._user = {
        admin: false,
        username: "",
        id: 0
      };
    }


    component.genId = (num=5)=>{
        if (typeof num !== 'number') {
            num = 5;
        }
        return '_' + new Date().getTime().toString().slice(-Math.abs(num));
    };
    component.debounceEvent = (c,t=250,i)=>(...d)=>clearTimeout(i,i=setTimeout(c,t,...d));
    component.cn = (classes)=>{
        return Object.entries(classes).reduce((acc, item) => {
            const [key, value] = item;
            if (value) return [...acc, key];
            return acc;
        }, []).join(" ");
    }
    component.query_params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    component.showFormErrors = (errors) => {
      if (!Array.isArray(errors)) {
        return Swal.fire('Validation Error', "The form as unknown errors", 'error');
      }
      const message = errors.map((err)=>{
        return `<p><strong>${err.field}:</strong>  ${err.message}</p>`;
      }).join("\n");
      return Swal.fire('Validation Error', message, 'error');
    },
    component.fmt = {
        date: (d)=>{
          return dayjs(d).format("YYYY-MM-DD");
        },
        datetime: (d)=>{
          return dayjs(d).format("YYYY-MM-DD HH:mm:ss");
        },
        datenice: (d)=>{
          return dayjs(d).format("MMM D, YYYY");
        },
        currency: (d)=>{
          return currency(d).format();
        },
        capitalize: (d)=>{
          return d.charAt(0).toUpperCase() + d.slice(1);
        },
        percent: (d)=>{
          return d + ' %'
        },
        rentaltype: (s)=>{
          switch(s){
            case 'year':
              return 'Year (1 month commission)';
            case 'month_6':
              return '6 Months (1/2 month commission)';
            case 'comm_15':
              return '15% Commission';
            case 'other':
              return 'Other';
            default:
              return 'Unknown (' + s + ')';
          }
        }
    }
    component.is = {
        array: (a)=>{
            return Array.isArray(a);
        },
        object: (a)=>{
            return typeof a === 'object';
        },
        string: (a)=>{
            return typeof a === 'string';
        },
        number: (a)=>{
            return typeof a === 'number';
        },
        boolean: (a)=>{
            return typeof a === 'boolean';
        },
        function: (a)=>{
            return typeof a === 'function';
        },
        null: (a)=>{
            return a === null;
        },
        undefined: (a)=>{
            return a === undefined;
        },
        empty: (a)=>{

            if(!a) return true;

            if (component.is.string(a)) {
              if(a.length === 0) {
                return true;
              }
              if(a.trim().length === 0) {
                return true;
              }
            }

            if (component.is.array(a) && a.length === 0) {
                return true;
            }

            if (component.is.object(a) && Object.keys(a).length === 0) {
                return true;
            }

            return false;
        }
    }
    return component;
});

// register
registerGlobalComponents()

// mount all the global components found in this page
mount('[data-riot-component]')
