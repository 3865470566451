<login-link>


    <h2>Please wait.... Checking authenication...... </h2>

    <p if="{!is.empty(state.error)}"></p>


    <script>

        import { loginlink } from "../../../lib/auth";

        export default {
            state:{
                error:null
            },
            onMounted() {
                console.log('Login Link mounted')
                this.redirect = this.query_params.redirect || this.query_params.r;

                if(!this.redirect) {
                    console.log("No redirect to were aborting");
                    //window.location = '/login.html'
                }
                else this.OnCheckLogin();
            },
            OnCheckLogin(){
                console.log("Checking login");
                return loginlink(
                    this.query_params.user,
                    this.query_params.staff,
                    this.query_params.name,
                    this.query_params.hash || this.query_params.auth
                ).then(res=>{
                    if(res){
                        this.update({error: "Redirecting...."});
                        this.Redirect(this.redirect, res);
                    }else{
                        this.update({error: "Unable to authenticate user"})
                    }
                })
            },
            Redirect(location, user){

                const queryparams = this.GetQueryParams(user)

                if(location=='agent' || location=="agentcommission"){
                    window.location = '/agent.html' + queryparams;
                }else{
                    window.location = this.redirect + queryparams;
                }
            },
            GetQueryParams(user){
                return '?user='+user.id+'&staff='+user.link_staff_id+'&name='+user.username;
            }
        }

    </script>

</login-link>
