/* Riot v7.1.0, @license MIT */
import { PLUGINS_SET } from '../node_modules/@riotjs/util/constants.js';
import { panic } from '../node_modules/@riotjs/util/misc.js';

/**
 * Uninstall a riot plugin
 * @param   {Function} plugin - plugin previously installed
 * @returns {Set} the set containing all the plugins installed
 */
function uninstall(plugin) {
  if (!PLUGINS_SET.has(plugin)) panic('This plugin was never installed');
  PLUGINS_SET.delete(plugin);
  return PLUGINS_SET;
}

export { uninstall };
