<commission-view-summary>
    <div class="card-body" if="{props.staff}">
        <div class="card-title">
            <div class="row">
                <div class="col-auto">
                                <span class="badge me-2 {cn.approval(props.commission.status)}">
                                    {props.commission.status}: {fmt.datenice(props.commission.status_at)}
                                </span>

                    <span class="badge bg-secondary me-2">{fmt.datenice(props.commission.status_at)}</span>

                    <span class="badge {cn.type(props.commission.commission_type)}">
                                    {props.commission.commission_type}
                                </span>

                    <h2 class="m-0 p-0">{props.staff.name_display} </h2>
                    <h5 class="m-0 p-0 text-black-50">{props.commission.property_address}
                        <span class="ms-2" style="cursor: pointer;" onclick="{onExcel}">
                                        <iconify-icon inline icon="vscode-icons:file-type-excel"></iconify-icon>
                                    </span>
                    </h5>

                </div>
                <div class="col">
                    <img class="border border-1 rounded-circle shadow float-end avatar-info"
                         src="https://sanmiguelrealestate.com/real-estate/about-us/picture/thumb2/{props.staff.id}"/>
                </div>
            </div>

        </div>
        <p>
        <table class="table">
            <tr if="{props.commission.sale_id}" class="row-sale">
                <td>
                    <strong>Linked Sale:</strong>
                </td>
                <td>
                    <span if="{!is.empty(props.sale)}">{props.sale.short_name}</span>
                </td>
                <td class="text-right">
                    <a class="text-black-50" href="/sales">
                        <iconify-icon inline icon="mdi:eye"></iconify-icon>
                    </a>
                </td>
            </tr>
            <tr>
                <td><strong>Under Contract:</strong> <div class="float-end">{fmt.datenice(props.commission.contract_date)}</div></td>
                <td colspan="2"><strong>Closing:</strong> <div class="float-end">{fmt.datenice(props.commission.closing_date)}</div></td>
            </tr>
            <tr>
                <td><strong class="me-2">{fmt.capitalize(props.commission.commission_type)}:</strong></td>
                <td>{fmt.currency(props.commission.commission_price)} {props.commission.commission_currency}</td>
                <td></td>
            </tr>
            <tr>
                <td><strong>Commission Total:</strong></td>
                <td>{fmt.currency(props.commission.commission_total)} {props.commission.commission_currency}</td>
                <td></td>
            </tr>
            <tr if="{props.commission.commission_type != 'rental'}">
                <td><strong class="me-2">Full Sale Commission:</strong></td>
                <td>{fmt.percent(props.commission.commission)}</td>
                <td></td>
            </tr>
            <tr if="{props.commission.commission_type == 'rental'}">
                <td><strong class="me-2">Rental Commission Type</strong></td>
                <td>{props.commission.rental_contract_type}</td>
            </tr>
            <tr>
                <td><strong>Realty Commission: </strong></td>
                <td>
                    {fmt.percent(props.commission.commission_agency)}

                </td>
                <td>
                                <span if="{props.commission.comission_agency == '' || props.commission.comission_agency == '0'}">
                                            <span class="badge bg-warning ms-3">Incomplete</span>
                                        </span>
                </td>
            </tr>
            <tr>
                <td><strong>Realty Side: </strong></td>
                <td>
                    {fmt.currency(state.commission.commission_agency_total)}

                </td>
                <td>
                                <span if="{props.commission.comission_agency_total == '' || props.commission.comission_agency_total == '0'}">
                                            <span class="badge bg-warning ms-3">Incomplete</span>
                                        </span>
                </td>
            </tr>
            <tr if="{!is.empty(props.commission.other_agency)}">
                <td><strong class="me-2">Other Agency</strong></td>
                <td>{props.commission.other_agency}</td>
            </tr>
            <tr if="{!is.empty(props.commission.other_agency)}">
                <td><strong class="me-2">Other Agency Commission</strong></td>
                <td>{fmt.percent(props.commission.commission-props.commission.commission_agency)}</td>
            </tr>

        </table>

        </p>

    </div>

    <script>

        import Swal from "sweetalert2";

        export default {
            onExcel() {
                Swal.fire("Coming Soon", "This feature is not yet available", "info");
            },
            onBeforeMount(){
                this.cn.type = (type) => {
                    if (type == 'sale') {
                        return 'bg-success';
                    } else {
                        return 'bg-primary';
                    }
                }

                this.cn.approval = (approval) => {
                    switch (approval) {
                        case 'approval':
                            return 'bg-success';
                        case 'reject':
                            return 'bg-danger';
                        case 'pending':
                            return 'bg-warning';
                        default:
                            return 'bg-secondary';
                    }
                };
            }
        }
    </script>
</commission-view-summary>
