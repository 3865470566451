import { register } from 'riot'
const globalComponents = require('./components/global/**/*.riot')

export default () => {
  Object.entries(globalComponents).map(([name, component]) => {
    
    console.log("Registering component: ",name,component);

    //console.log(component[name]);
    if(component[name] && component[name]){
        //console.log("Registering component: ",name);
        register(name, component[name].default)
    }

    return {
      name,
      component
    }
  })
}
