<modal-sale-link>

    <!-- Modal -->
    <div class="modal fade" id="{state.elid}" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Link to sale</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-12">
                            <select id="select-sales" name="select-sales" class="form-select form-control">
                                <option value="">-- Select a sale --</option>
                                <option each="{sale in state.sales}" value="{sale.ID}">{sale.short_name}</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" onclick="{onSave}">Link Sale</button>
                </div>
            </div>
        </div>
    </div>

    <script>

        import { api_get , api_post } from '../../lib/api.js';
        import Swal from 'sweetalert2';
        export default {
            state:{
                elid: 'modal-sale-link',
                sales: [],
                selected_sale: null,
                commission_id: null
            },
            onBeforeMount() {
                this.state.elid = "modal-sale-link-" + this.state.cid || this.genId();
                this.state.sales = [];
            },
            onMounted(props){
                this.events = [];
                this.modal = new bootstrap.Modal(this.getModal(),{});
                const event1 = PubSub.subscribe('model.sale.link.show', this.onShow.bind(this));
                const event2 = PubSub.subscribe('model.sale.link.hide', this.onHide.bind(this));
                this.events.push(event1, event2);
                //this.getSales();
            },
            onUnmounted() {
                this.events.forEach(e => PubSub.unsubscribe(e));
                this.modal.dispose();
            },
            getModal(){
                return document.getElementById(this.state.elid);
            },
            getSales(){
                console.log("Link Sales");
                return api_get('commission/sale').then(res => {
                    console.log(res);
                    if(res.status == 200 && res.data.sales){

                        this.update({
                            sales: res.data.sales
                        });

                    }else{
                        console.warn("Could not load sales.");
                    }

                });
            },
            onShow(msg,data){
                console.log('model.sale.link.show', data);

                this.commission = data;

                console.log("Show");
                this.modal.show();

                console.log("Load Sales");
                this.getSales();


            },
            onHide(msg,data){
                console.log('model.sale.link.hide', data);
                this.modal.hide();
            },
            onSave(){
                const commission_id = this.commission.ID;


                if(!this.is.number(commission_id)){
                    return Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Could not link sale to commission. Commission ID is invalid.',
                    });
                }

                const sale_id = this.$('#select-sales').value;

                if(sale_id == ''){
                    return Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'No sale selected.',
                    });
                }

                const sale_id_int = parseInt(sale_id, 10);

                if(!this.is.number(sale_id_int)){
                    return Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Could not link sale to commission. Sale ID is invalid.',
                    });
                }

                console.log("Save", commission_id, sale_id_int);

                return api_post('/commission/sale/link',{sale_id: sale_id_int, commission_id:commission_id}).then(res=>{
                    PubSub.publish('model.sale.link.done', {sale: res.data, commission_id: commission_id});
                    this.onHide();
                });


            }
        }

    </script>

</modal-sale-link>
