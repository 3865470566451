import axios from "axios";

let _server = window.settings.API_SERVER;
let _port = window.settings.API_PORT;
let _token = '';
let _base = 'api/'
let _protocol = window.settings.API_PROTOCOL;
let _url;

if(_port==0 || _port=='0') {
  _url = `${_protocol}://${_server}/${_base}`
}else{
  _url = `${_protocol}://${_server}:${_port}/${_base}`
}


_token = (window && window._auth) ? window._auth.token : '';
if(!_token || _token=='') _token = localStorage.getItem('token');
if(!_token || _token=='') _token = document.cookie.match('(^|;)\\s*token\\s*=\\s*([^;]+)')?.pop() || '';
axios.defaults.headers.common['Authorization'] = _token;

export function set_base(base){
    _base = base;
}

export function set_server(host, port){
    _server = host,
    _port = port
}

export function set_authtoken(token){
    _token = token;
}

export function api_get(path, data){
    return _api('GET', path, data);
}

export function api_put(path, data){
    return _api('PUT', path, data);
}

export function api_delete(path, data){
    return _api('DELETE', path, data);
}

export function api_post(path, data){
    return _api('POST', path, data);
}

function _api(method,path,data){
    const headers = {};
    /*if(_token && _token!=""){
        headers.Authorization = 'Bearer ' + _token;
    }*/


    return axios.request({
        url: path,
        method: method,
        baseURL: _url,
        headers: headers,
        params: method=='GET' ? data : {},
        data: method!='GET' ? data : {},
        timeout: 10000,
        responseType: 'json'
    }).then((response) => {
        if(response.status==401){
            window.location.href = '/login.html';
        }
        return response;
    })
}
