<agent-commissions-list>

    <div class="container-fluid ">
    <div class="row flex-nowrap">
        <div class="col">
            <h2 class="mb-5 mt-2">Agent Commissions History
                <a href="{state.formURL}" class="btn btn-primary ms-3">
                    <iconify-icon inline icon="material-symbols:add"></iconify-icon>
                    Create New
                </a>
            </h2>

            <div if="{is.empty(state.commissions)}">
                <p>
                    You currently have no commission reports registered. Please create a new one <a href="{state.formURL}">here</a>.
                </p>
            </div>

            <table class="table" if="{!is.empty(state.commissions)}">

                <thead>
                    <tr>
                        <th scope="col">Agent</th>
                        <th scope="col">Type</th>
                        <th scope="col">Contract Date</th>
                        <th scope="col">Amount</th>

                        <th scope="col">Comm %</th>
                        <th scope="col">Commission</th>

                        <th scope="col">Action</th>
                    </tr>
                </thead>

                <tbody>
                    <tr each="{c in state.commissions}">
                        <td>{c.staff.name_display}</td>
                        <td>{c.commission_type}</td>
                        <td>{fmt.datenice(c.create_at)}</td>

                        <td>
                            {fmt.currency(c.commission_total)}
                            <span if="{c.commission_currency == 'MXN'}"><iconify-icon inline icon="twemoji:flag-mexico"></iconify-icon></span>
                        </td>
                        <td>
                            {fmt.percent(c.commission_agent)}
                        </td>
                        <td>
                            {fmt.currency(c.commission_agent_total)}
                            <span if="{c.commission_currency == 'MXN'}"><iconify-icon inline icon="twemoji:flag-mexico"></iconify-icon></span>
                        </td>
                        <td>
                            <a data-cid="{c.ID}" class="btn btn-primary" href="form.html?edit_id={c.ID}">
                                <iconify-icon inline icon="material-symbols:edit-document"></iconify-icon>
                            </a>
                        </td>
                    </tr>
                </tbody>


            </table>


        </div>
    </div>
    </div>


    <script>
        import Swal from 'sweetalert2';
        import { api_get } from '../../lib/api';
        export default {
            state: {
                user_id: 0,
                user_username: '',
                role: false,
                commissions: [],
                formURL: '/form.html'
            },
            onBeforeMount() {
                if(this._user && this._user.id) {
                    this.state.user__id = this._user.id;
                    this.state.user_id = this.query_params.staff || this._user.id;
                    this.state.user_username = this._user.username;
                    this.state.role = this._user.role;
                }else{
                    this.state.user_id = this.query_params.staff;
                    this.state.user__id = this.query_params.user;
                    this.state.username = this.query_params.name;
                    this.state.admin = false;
                }

                this.state.formURL = '/form.html?user='+this.state.user__id+'&staff='+this.state.user_id+'&name='+this.state.username;

                console.log("Agent Before Mount");
            },
            onMounted() {
                this.getList();
                console.log("Agent Mounted");
            },
            getList(){
                return api_get('/commission/agent/' + this.state.user_id).then((res)=>{
                    console.log(res);
                    if(res.status == 200 && !res.data.success){
                        this.update({
                            commissions: res.data
                        });

                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong! Could not get agent data.',
                        });
                    }
                });
            },

            onEdit(e) {
                const el = e.target.closest('button');
                const cid = el.dataset.cid || false;
                console.log(cid);
            },
        }
    </script>
</agent-commissions-list>
