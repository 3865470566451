/* Riot v7.1.0, @license MIT */
import bindingTypes from '../node_modules/@riotjs/util/binding-types.js';
import { DOM_COMPONENT_INSTANCE_PROPERTY, PARENT_KEY_SYMBOL } from '../node_modules/@riotjs/util/constants.js';
import expressionTypes from '../node_modules/@riotjs/util/expression-types.js';
import { template as create, createBinding as create$1, createExpression as create$4 } from '../node_modules/@riotjs/dom-bindings/dist/esm.dom-bindings.js';
import cssManager from '../core/css-manager.js';

// expose some internal stuff that might be used from external tools
const __ = {
  cssManager,
  DOMBindings: {
    template: create,
    createBinding: create$1,
    createExpression: create$4,
    bindingTypes,
    expressionTypes
  },
  globals: {
    DOM_COMPONENT_INSTANCE_PROPERTY,
    PARENT_KEY_SYMBOL
  }
};

export { __ };
