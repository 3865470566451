<start-form>

    <div class="container-fluid px-1 py-5 mx-auto">
            <commission-form />
    </div>

    <script>
        
        import CommissionForm from '../../commission/commission-form.riot';
        //import lazy from '@riotjs/lazy'

        export default {
            components: {
                CommissionForm
            },
            onMounted() {
                console.log('Start Form mounted')
            }
        }

    </script>
</start-form>
