/* Riot v7.1.0, @license MIT */
import { DOM_COMPONENT_INSTANCE_PROPERTY } from '../node_modules/@riotjs/util/constants.js';

/**
 * Bind a DOM node to its component object
 * @param   {HTMLElement} node - html node mounted
 * @param   {Object} component - Riot.js component object
 * @returns {Object} the component object received as second argument
 */
const bindDOMNodeToComponentInstance = (node, component) => node[DOM_COMPONENT_INSTANCE_PROPERTY] = component;

export { bindDOMNodeToComponentInstance };
