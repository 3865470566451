/* Riot v7.1.0, @license MIT */
import { createCoreAPIMethods } from './create-core-api-methods.js';
import { createExpression as create$4 } from '../node_modules/@riotjs/dom-bindings/dist/esm.dom-bindings.js';

/**
 * Create the bindings to update the component attributes
 * @param   {HTMLElement} node - node where we will bind the expressions
 * @param   {Array} attributes - list of attribute bindings
 * @returns {TemplateChunk} - template bindings object
 */
function createAttributeBindings(node, attributes) {
  if (attributes === void 0) {
    attributes = [];
  }
  const expressions = attributes.map(a => create$4(node, a));
  const binding = {};
  return Object.assign(binding, Object.assign({
    expressions
  }, createCoreAPIMethods(method => scope => {
    expressions.forEach(e => e[method](scope));
    return binding;
  })));
}

export { createAttributeBindings };
