<modal-commission-split>


    <!-- Modal -->
    <div class="modal fade" id="{state.elid}" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Approved Commission Split</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <div class="mb-3">
                        <label for="split_percent" class="form-label">Final Percent</label>
                        <input type="number" class="form-control" id="split_percent" aria-describedby="split_permission">
                    </div>

                    <div class="mb-3">
                        <label for="split_amount" class="form-label">Final Amount</label>
                        <input type="hidden" class="form-control" id="split_amount" aria-describedby="split_permission">
                        <input-currency hidden-id="split_amount" placeholder="Commission to Agent" aria-describedby="split_permission"></input-currency>
                    </div>

                    <div class="mb-3">
                        <label for="split_amount" class="form-label">Transaction Side:</label>
                        <select class="form-select" id="buyer_seller" aria-label="Default select example">
                            <option value="">-- SELECT --</option>
                            <option value="buyer">Buyer</option>
                            <option value="seller">Seller</option>
                            <option value="na">n/a</option>
                        </select>
                    </div>


                    <div class="mb-3">
                        <label for="split_expense" class="form-label">Final Expense</label>
                        <input type="hidden" class="form-control" id="split_expense">
                        <input-currency hidden-id="split_expense" placeholder="Expense Agent Pays" aria-describedby="split_permission"></input-currency>
                    </div>

                    <div class="mb-3">
                        <label for="split_agency_credit" class="form-label">Final Agency Credit Ponits</label>
                        <input type="number" class="form-control" id="split_agency_credit" aria-describedby="split_permission">
                    </div>

                    <div class="mb-3">
                        <label for="split_notes" class="form-label">Notes</label>
                        <textarea class="form-control" id="split_notes" aria-describedby="split_permission"></textarea>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" onclick="{onSave}">Save changes</button>
                </div>
            </div>
        </div>
    </div>


    <script>
        import InputCurrency from "../inputs/input-currency.riot";
        import Swal from 'sweetalert2'
        import Schema from 'async-validator';
        import PubSub from "pubsub-js";
        import {api_post} from "../../lib/api";

        export default {
            components: {
                InputCurrency
            },
            state:{
                commission: {},
            },
            onBeforeMount(){
                this.state.elid = 'modal-commission-split' + this.genId();
            },
            onMounted(){
                this.events = [];
                this.modal = new bootstrap.Modal(this.getModal(),{});
                const event1 = PubSub.subscribe('model.commission.split.show', this.onShow.bind(this));
                const event2 = PubSub.subscribe('model.commission.split.hide', this.onHide.bind(this));

                this.events.push(event1, event2);
            },
            onUnmounted(){
                this.events.forEach(e => PubSub.unsubscribe(e));
                this.modal.dispose();
            },
            getModal(){
                return document.getElementById(this.state.elid);
            },
            onShow(msg, data) {
                this.modal.show();
                this.update({
                    commission: data
                });

                const e = new Event('change');
                this.$('#split_percent').value = data.commission_agent;
                const $amount = this.$('#split_amount');
                $amount.value = data.commission_agent_total;
                $amount.dispatchEvent(e);

                const $expense = this.$('#split_expense');
                $expense.value = 0;
                $expense.dispatchEvent(e);

                const $agency_credit = this.$('#split_agency_credit');
                $agency_credit.value = 0;
                $agency_credit.dispatchEvent(e);
            },
            onHide(msg, data) {
                this.clear();
                this.modal.hide();
            },
            clear(){
                const e = new Event('change');
                this.$('#split_percent').value = '';
                const $amount = this.$('#split_amount');
                $amount.value = '';
                $amount.dispatchEvent(e);

                const $expense = this.$('#split_expense');
                $expense.value = 0;
                $expense.dispatchEvent(e);

                const $agency_credit = this.$('#split_agency_credit');
                $agency_credit.value = 0;
                $agency_credit.dispatchEvent(e);
            },
            onSave(){
                const data = this.getData();
                this.verify(data).then(()=>{
                    this.sendServer(data);
                }).catch(err => {
                    if(err.errors){
                        return this.showFormErrors(err.errors);
                    }
                })
            },
            sendServer(data){
                api_post('commission/split', data).then(res=>{
                    if(res.status === 200){
                        Swal.fire({
                            title: 'Success!',
                            text: 'Commission Split Updated',
                            icon: 'success',
                            confirmButtonText: 'Cool'
                        })
                        this.modal.hide();
                        PubSub.publish('model.commission.split.saved', data);
                    }else{
                        Swal.fire({
                            title: 'Error!',
                            text: 'Commission Split Update Failed',
                            icon: 'error',
                            confirmButtonText: 'Cool'
                        })
                    }
                });
            },
            verify(data){
                const desc = {
                    percentage: {
                        type: 'number',
                        required: true,
                        min: 0,
                        max: 100
                    },
                    amount: {
                        type: 'number',
                        required: true,
                        min: 0
                    },
                    expense: {
                        type: 'number',
                        required: true,
                        min: 0
                    },
                    agency_credit: {
                        type: 'number',
                        required: true,
                        min: 0
                    },
                    notes: {
                        type: 'string',
                        required: false,
                    },
                    buyer_seller: {
                        type: 'string',
                        required: true,
                        enum: ['buyer', 'seller', 'na']
                    },
                };

                const validator = new Schema(desc);
                return validator.validate(data);
            },
            getData(){
                 const $ = el=>this.$("#"+el);

                return {
                    commission_id: this.state.commission ? this.state.commission.ID : null,
                    percentage: parseFloat( $('split_percent').value ),
                    amount: parseFloat( $('split_amount').value ),
                    expense: parseFloat( $('split_expense').value ),
                    agency_credit: parseFloat( $('split_agency_credit').value ),
                    notes: $('split_notes').value,
                    buyer_seller: $('buyer_seller').value,
                }
            },
        }
    </script>

</modal-commission-split>
