<commission-form>
    <div class="row d-flex justify-content-center mx-auto">
        <div class="col-xl-7 col-lg-8 col-md-9 col-11 text-center">
            <h3 class="form-title">Under Contract & Commission Report</h3>
            

            <!-- Basic Information -->

            <form class="form-card" onsubmit="{onPreventSubmit}">



            <div class="card pt-2 {cn({'hidden-card':state.step!=1})}">

                <step-display steps="{commission_type!='rental' ? state.maxstep : state.maxstep - 2}" current="{state.step}" if="{state.step==1}"/>

                <h5 class="text-center mb-4 mt-3">
                    Basic Information
                </h5>
                
                    <div class="row justify-content-between text-left">
                        <div class="form-group col-12 flex-column d-flex"> 
                            <label class="form-control-label px-3 pb-2" for="for_id">Report For<span class="text-danger"> *</span></label>
                            <select class="form-select" type="text" id="for_id" name="for_id" placeholder="Commission report for Agent"> 
                                <option value="">-- select staff --</option>
                                <option value="{s.id}" each="{s in state.staff}" selected="{s.id == state.selected_staff}">{s.name_display}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row justify-content-between text-left mt-3">
                        <div class="form-group col-sm-6 flex-column d-flex"> 
                            <label class="form-control-label px-3" for="contract_date">Under Contract Date<span class="text-danger"> *</span></label> 
                            <input type="date" id="contract_date" name="contract_date" placeholder="Contract Date"> 
                        </div>

                        <div class="form-group col-sm-6 flex-column d-flex"> 
                            <label class="form-control-label px-3" for="closing_date">Estimated Closing Date<span class="text-danger"> *</span></label> 
                            <input type="date" id="closing_date" name="closing_date" placeholder="Closing Date"> 
                        </div>
                    </div>
                    <div class="row justify-content-between text-left mt-3">
                        <div class="form-group col-sm-6 flex-column d-flex"> 
                            <label class="form-control-label px-3"for="property_ref">Property Ref #</label> 
                            <input type="number" id="property_ref" name="property_ref" placeholder="Property ID"> 
                        </div>
                        <div class="form-group col-sm-6 flex-column d-flex"> 
                            <label class="form-control-label px-3">Commission Type<span class="text-danger"> *</span></label> 
                            <div class="btn-group mt-2" role="group" aria-label="Basic radio toggle button group">
                                <input onclick="{onCommissionTypeSel}" type="radio" class="btn-check" name="commission_type" id="commission_type_sale" autocomplete="off" checked>
                                <label class="btn btn-outline-primary" for="commission_type_sale">Sales</label>
                            
                                <input onclick="{onCommissionTypeSel}" type="radio" class="btn-check" name="commission_type" id="commission_type_rental" autocomplete="off">
                                <label class="btn btn-outline-primary" for="commission_type_rental">Rental</label>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row justify-content-between text-left mt-3">
                        <div class="form-group col-12 flex-column d-flex"> 
                            <label class="form-control-label px-3" for="property_address">Street Address<span class="text-danger"> *</span></label> 
                            <input type="text" id="property_address" name="property_address" placeholder="" onblur=""> 
                        </div>
                    </div>

                    <div class="row justify-content-between text-left mt-3">
                        <div class="form-group col-6 flex-column d-flex">
                            <label class="form-control-label px-3" for="other_agency">Other Agency & Agent</label>
                            <input type="text" id="other_agency" name="other_agency" placeholder="" onblur="">
                        </div>
                        <div class="form-group col-6 flex-column d-flex">
                            <label class="form-control-label px-3">Other Agency Is</label>
                            <div class="btn-group mt-2" role="group" aria-label="Basic radio toggle button group">
                                <input value="USD" type="radio" class="btn-check" name="other_is" id="other_is_buyer" autocomplete="off" checked>
                                <label class="btn btn-outline-primary" for="other_is_buyer">Buyer</label>

                                <input value="MXN" type="radio" class="btn-check" name="other_is" id="other_is_seller" autocomplete="off">
                                <label class="btn btn-outline-primary" for="other_is_seller">Seller</label>

                                <input value="MXN" type="radio" class="btn-check" name="other_is" id="other_is_na" autocomplete="off">
                                <label class="btn btn-outline-primary" for="other_is_na">N/A</label>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-between text-left mt-3">
                        <div class="form-group col-sm-6 flex-column d-flex"> 
                            <label class="form-control-label px-3">
                                <span if="{state.commission_type=='sale'}">Sale Price (Without IVA)</span>
                                <span if="{state.commission_type=='rental'}">Total Rental Price (Contract)</span>
                            <span class="text-danger"> *</span></label> 
                            <input-currency onupdate={onCurrencyInput} hidden-id="commission_price" name="commission_price_" placeholder="Total Sales/Rental Price" />
                            <!-- input type="number" hidden-id="commission_price" id="commission_price" name="commission_price" placeholder="Total Price" onblur="" -->
                            <input type="hidden" id="commission_price">
                        </div>
                        <div class="form-group col-sm-6 flex-column d-flex"> 
                            <label class="form-control-label px-3">Currency<span class="text-danger"> *</span></label> 
                            <div class="btn-group mt-2" role="group" aria-label="Basic radio toggle button group">
                                <input value="USD" type="radio" class="btn-check" name="currency" id="currency_usd" autocomplete="off" checked>
                                <label class="btn btn-outline-primary" for="currency_usd">US Dollar</label>
                            
                                <input value="MXN" type="radio" class="btn-check" name="currency" id="currency_mxn" autocomplete="off">
                                <label class="btn btn-outline-primary" for="currency_mxn">Mexican Peso</label>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-between text-left mt-3 {cn({'hidden-row':commission_type=='rental'})}">
                        <div class="form-group col-sm-6 flex-column d-flex"> 
                            <label class="form-control-label px-3">Commission % (All Parties)<span class="text-danger"> *</span></label> 
                            <input type="number" id="commission" name="commission"
                                   placeholder="Total commission for all parties" onkeyup="{onCalcTotalCommission}" onblur="{onCalcTotalCommission}" value="{state.commission}">
                        </div>
                        <div class="form-group col-sm-6 flex-column d-flex"> 
                            <label class="form-control-label px-3">Total Commission<span class="text-danger"> *</span></label>
                            <input-currency hidden-id="commission_total" onupdate={onCurrencyInput} name="commission_total_" placeholder="Total Commision for All" />
                            <input type="hidden" id="commission_total">
                        </div>
                    </div>


                    <div class="row justify-content-center mt-4">
                        <div class="form-group col-sm-6"> 
                            <button type="submit" class="btn-block btn-danger m-2" onclick="{onSave}">Save Under Contract</button>
                            <button type="submit" class="btn-block btn-primary m-2" onclick="{onNext}">
                                Next
                                <iconify-icon icon="material-symbols:arrow-forward-ios-rounded" inline></iconify-icon>
                            </button> 
                        </div>
                    </div>
                
            </div>

            <!-- Realty Comimmision -->

            <div class="card pt-2 {cn({'hidden-card':state.step!=2})}">

                <step-display steps="{commission_type!='rental' ? state.maxstep : state.maxstep - 2}" current="{state.step}" if="{state.step==2}"/>

                <h5 class="text-center mb-4 mt-3">
                    Commission For <strong>Realty San Miguel</strong> side
                </h5>
                <p if="{state.commission_type=='sale'}">
                    Please provide the commission details for the Realty San Miguel side. 
                    This should include your commission as well as the commission for others in Realty San Miguel. 
                    If this is a sale, please specify the total percentage of the commission that our side receives. 
                    For example, if the total commission is 6% and Realty San Miguel receives 3%, the percentage below should be set at 3%. 
                    The total commission amount should be 3% of the sales price.
                </p>

                <p if="{state.commission_type=='rental'}">
                    Please Provide commission details for Realty San Miguel side of this rental, 
                    including your commission and commission for Realty San Miguel. Specify rental contract type. 
                </p>

                <div class="row justify-content-between text-left mt-3">
                    <div class="form-group col-sm-6 flex-column d-flex {cn({'hidden-field':state.commission_type!='sale'})} ">
                        <label class="form-control-label px-3" for="commission_agency">% of Total Commission<span class="text-danger"> *</span></label> 
                        <input onchange="{onCalcAgencyTotal}" onkeyup="{onCalcAgencyTotal}" onblur="{onCalcAgencyTotal}"
                               type="number" id="commission_agency" name="commission_agency"
                               step=".1" min="0"
                               placeholder="Enter the % of the Realty Side" value="{state.commission_agency}">
                    </div>

                    <div class="form-group col-sm-6 flex-column d-flex {cn({'hidden-field':state.commission_type!='rental'})}">
                        <label class="form-control-label px-3" for="rental_contract_type">Rental Contract Type<span class="text-danger"> *</span></label>
                        <select class="form-select m-1" id="rental_contract_type" name="rental_contract_type"
                                placeholder="Enter the % of the Realty Side" onchange="{onUpdateRentalCommission}" onkeyup="{onUpdateRentalCommission}" onblur="{onUpdateRentalCommission}">
                            <option value="{r.code}" each="{r in state.rental_comm_types}">{r.name}</option>
                        </select>
                    </div>

                    <div class="form-group col-sm-6 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="commission_agency_total">Total Commission Amount<span class="text-danger"> *</span></label>
                        <input type="hidden" id="commission_agency_total">
                        <input-currency hidden-id="commission_agency_total" name="commission_agency_total_" placeholder="Enter $ amount of total commission" />
                    </div>
                </div>


                <div class="row justify-content-center mt-4">
                    <div class="form-group col-sm-6">
                        <button type="submit" class="btn-block btn-primary m-2" onclick="{onBack}">
                            <iconify-icon icon="material-symbols:arrow-back-ios-rounded" inline></iconify-icon>
                            Back
                        </button> 
                        <button type="submit" class="btn-block btn-danger m-2" onclick="{onSave}" >Save</button>
                        <button type="submit" class="btn-block btn-primary m-2" onclick="{onNext}">
                            Next
                            <iconify-icon icon="material-symbols:arrow-forward-ios-rounded" inline></iconify-icon>
                        </button> 
                    </div>
                </div>
            </div>

            <!-- Your Commission -->

            <div class="card pt-2 {cn({'hidden-card':state.step!=3})}">

                <step-display steps="{commission_type!='rental' ? state.maxstep : state.maxstep - 2}" current="{state.step}" if="{state.step==3}"/>


                <h5 class="text-center mb-4 mt-3">
                    Commission For <strong>You!</strong>
                </h5>

                <p>
                    Please provide the commission details for you. As an example, 
                    if the total commission for the Realty San Miguel side is $1000 and your commission level is 60%,
                    you would enter 60% and $600 in the fields below.
                    Please be sure to provide the correct percentages and amounts for your commission.
                    For rentals, the commission for Realty San Miguel should be 20% and your commission should be 80%.
                </p>

                <h6> Splitting Commission with Multiple Agents in Real Estate </h6>

                <p>
                    If you are splitting your real estate commission in San Miguel with others,
                    please adjust the total amount to reflect the amount you expect to receive.
                </p>

                <h6> Managing Commission Levels for Sales </h6>
                <p>
                    if you are receiving various commission levels, simply add the percentage for your current level
                    and leave a comment at the end of this form. Support for multi-level commission is coming soon.
                </p>

                <div class="row justify-content-between text-left mt-3">
                    <div class="form-group col-sm-6 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="commission_agent">% of Your Comission<span class="text-danger"> *</span></label> 
                        <input type="number" id="commission_agent" name="commission_agent"
                               min="0" step="1"
                               placeholder="Enter the % of the Realty Side"
                               onchange="{onCalcAgentCommission}"
                               onkeyup="{onCalcAgentCommission}"
                               onblur="{onCalcAgentCommission}">
                    </div>

                    <div class="form-group col-sm-6 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="commission_agent_total">Your Total Commission<span class="text-danger"> *</span></label> 
                        <input type="hidden" id="commission_agent_total" >
                        <input-currency hidden-id="commission_agent_total" name="commission_agent_total_" placeholder="Enter $ amount of total commission" />
                    </div>
                </div>

                <div class="row justify-content-center mt-4">
                    <div class="form-group col-sm-6">
                        <button type="submit" class="btn-block btn-primary m-2" onclick="{onBack}">
                            <iconify-icon icon="material-symbols:arrow-back-ios-rounded" inline></iconify-icon>
                            Back
                        </button> 
                        <button type="submit" class="btn-block btn-danger m-2" onclick="{onSave}">Save</button>
                        <button type="submit" class="btn-block btn-primary m-2" onclick="{onNext}">
                            Next
                            <iconify-icon icon="material-symbols:arrow-forward-ios-rounded" inline></iconify-icon>
                        </button> 
                    </div>
                </div>
            </div>

            <!-- Expenses -->

            <div class="card pt-2 {cn({'hidden-card':state.step!=4})}">

                <step-display steps="{commission_type!='rental' ? state.maxstep : state.maxstep - 2}" current="{state.step}" if="{state.step==4}"/>


                <h5 class="text-center mb-4 mt-3">
                    Additional Expenses
                </h5>

                <p>
                    Did you incur any external expenses related to this sale that Realty agreed to share the cost of?
                    If not, please leave the fields below empty.
                    If you did incur such expenses, please list them including the purpose and amount of each expense.
                </p>
                
                <div class="row justify-content-between text-left mt-3">

                    <div class="form-group col-5 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="expense_description_1">Reason for Expense</label> 
                        <input type="text" id="expense_description_1" name="expense_description_1" placeholder="Description">
                    </div>

                    <div class="form-group col-3 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="expense_currency_1">Currency</label>
                        <select class="form-select mt-2" name="expense_currency_1" id="expense_currency_1">
                            <option value="{c.code}" each="{c in state.currencies}">{c.name}</option>
                        </select>
                    </div>

                    <div class="form-group col-4 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="expense_amount_1">Amount</label> 
                        <input type="hidden" id="expense_amount_1" >
                        <input-currency hidden-id="expense_amount_1" name="expense_amount_1_" placeholder="Total Cost" />
                    </div>

                </div>

                <div class="row justify-content-between text-left mt-3">

                    <div class="form-group col-5 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="expense_description_2">Reason for Expense</label> 
                        <input type="text" id="expense_description_2" name="expense_description_2" placeholder="Description">
                    </div>

                    <div class="form-group col-3 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="expense_currency_2">Currency</label>
                        <select class="form-select mt-2" name="expense_currency_2" id="expense_currency_2">
                            <option value="{c.code}" each="{c in state.currencies}">{c.name}</option>
                        </select>
                    </div>

                    <div class="form-group col-4 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="expense_amount_2">Amount</label>
                        <input type="hidden" id="expense_amount_2" >
                        <input-currency hidden-id="expense_amount_2" name="expense_amount_2_" placeholder="Total Cost" />
                    </div>

                </div>

                <div class="row justify-content-between text-left mt-3">

                    <div class="form-group col-5 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="expense_description_3">Reason for Expense</label> 
                        <input type="text" id="expense_description_3" name="expense_description_3" placeholder="Description">
                    </div>

                    <div class="form-group col-3 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="expense_currency_3">Currency</label>
                        <select class="form-select mt-2" name="expense_currency_3" id="expense_currency_3">
                            <option value="{c.code}" each="{c in state.currencies}">{c.name}</option>
                        </select>
                    </div>

                    <div class="form-group col-4 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="expense_amount_3">Amount</label>
                        <input type="hidden" id="expense_amount_3" >
                        <input-currency hidden-id="expense_amount_3" name="expense_amount_3_" placeholder="Total Cost" />
                    </div>
                </div>


                <div class="row justify-content-center mt-4">
                    <div class="form-group col-sm-6">
                        <button type="submit" class="btn-block btn-primary m-2" onclick="{onBack}">
                            <iconify-icon icon="material-symbols:arrow-back-ios-rounded" inline></iconify-icon>
                            Back
                        </button> 
                        <button type="submit" class="btn-block btn-danger m-2" onclick="{onSave}">Save</button>
                        <button type="submit" class="btn-block btn-primary m-2" onclick="{onNext}">
                            Next
                            <iconify-icon icon="material-symbols:arrow-forward-ios-rounded" inline></iconify-icon>
                        </button> 
                    </div>
                </div>
            </div>

            <!-- Factura -->

            <div class="card pt-2 {cn({'hidden-card':state.step!=5})}">

                <step-display steps="{commission_type!='rental' ? state.maxstep : state.maxstep - 2}" current="{state.step}" if="{state.step==5}"/>

                <h5 class="text-center mb-4 mt-3">
                    Client Factura?
                </h5>

                <p>
                    If the client requires a factura, please provide their information in the fields below.
                    This should include their name, tax identification number, and any other relevant details.
                </p>

                <div class="row justify-content-between text-left mt-3">
                    <div class="form-group col-sm-12 flex-column d-flex"> 
                        <label class="form-control-label px-3">Client Needs Factura?<span class="text-danger"> *</span></label> 
                        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                            <input value="1" type="radio" class="btn-check" name="factura" id="factura_yes" autocomplete="off" >
                            <label class="btn btn-outline-primary" for="factura_yes" data-sel="1" onclick="{onNeedFactura}">Yes</label>
                        
                            <input value="0" type="radio" class="btn-check" name="factura" id="factura_no" autocomplete="off" checked>
                            <label class="btn btn-outline-primary" for="factura_no" data-sel="0" onclick="{onNeedFactura}">No</label>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-between text-left mt-3" if="{state.needs_factura}">
                        <div class="form-group col-12 flex-column d-flex"> 
                            <label class="form-control-label px-3" for="client_name_legal">Client Legal Name<span class="text-danger"> *</span></label> 
                            <input type="text" id="client_name_legal" name="client_name_legal" placeholder=""> 
                        </div>
                </div>

                <div class="row justify-content-between text-left mt-3" if="{state.needs_factura}">
                    <div class="form-group col-6 flex-column d-flex">
                        <label class="form-control-label px-3" for="client_rfc">Client RFC<span class="text-danger"> *</span></label> 
                        <input type="text" id="client_rfc" name="client_rfc" placeholder="" onblur=""> 
                    </div>
                    <div class="form-group col-6 flex-column d-flex">
                        <label class="form-control-label px-3" for="client_regimen_fiscal">Régimen Fiscal<span class="text-danger"> *</span></label>
                        <input type="text" id="client_regimen_fiscal" name="client_regimen_fiscal" placeholder="" onblur="">
                    </div>
                </div>

                <div class="row justify-content-between text-left mt-3" if="{state.needs_factura}">
                    <div class="form-group col-12 flex-column d-flex"> 
                        <label class="form-control-label px-3">Street Address<span class="text-danger"> *</span></label> 
                        <input type="text" id="client_address_street" name="client_address_street" placeholder="" onblur=""> 
                    </div>
                </div>

                <div class="row justify-content-between text-left mt-3" if="{state.needs_factura}">

                    <div class="form-group col-5 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="client_address_city">City</label> 
                        <input type="text" id="client_address_city" name="client_address_city" placeholder="City">
                    </div>

                    <div class="form-group col-3 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="client_address_state">State</label> 
                        <input type="text" id="client_address_state" name="client_address_state" placeholder="State">
                    </div>

                    <div class="form-group col-4 flex-column d-flex"> 
                        <label class="form-control-label px-3" client="client_address_postal">Postal</label> 
                        <input type="text" id="client_address_postal" name="client_address_postal" placeholder="Postal Code">
                    </div>

                </div>



                <div class="row justify-content-center mt-4">
                    <div class="form-group col-sm-6">
                        <button type="submit" class="btn-block btn-primary m-2" onclick="{onBack}">
                            <iconify-icon icon="material-symbols:arrow-back-ios-rounded" inline></iconify-icon>
                            Back
                        </button> 
                        <button type="submit" class="btn-block btn-danger m-2" onclick="{onSave}">
                            <span if="{state.step!==state.maxstep}">Save</span>
                            <span if="{state.step==state.maxstep}">Finsih</span>
                        </button>
                        <button type="submit" class="btn-block btn-primary m-2" onclick="{onNext}" if="{state.step!==state.maxstep}">
                            Next
                            <iconify-icon icon="material-symbols:arrow-forward-ios-rounded" inline></iconify-icon>
                        </button> 
                    </div>
                </div>
            </div>


            <!-- Comments  -->
            
            <div class="card pt-2 {cn({'hidden-card':state.step!=6})}">

                <step-display steps="{commission_type!='rental' ? state.maxstep : state.maxstep - 2}" current="{state.step}" if="{state.step==6}"/>

                <div class="row justify-content-between text-left mt-3">
                    <div class="form-group col-12 flex-column d-flex"> 
                        <label class="form-control-label px-3" for="client_rfc">Comments<span class="text-danger"> *</span></label> 
                        <input type="text" id="comments" name="comments" placeholder="" onblur=""> 
                    </div>
                </div>

                <div class="row justify-content-center mt-4">
                    <div class="form-group col-sm-6">
                        <button type="submit" class="btn-block btn-primary m-2" onclick="{onBack}">
                            <iconify-icon icon="material-symbols:arrow-back-ios-rounded" inline></iconify-icon>
                            Back
                        </button> 
                        <button type="submit" class="btn-block btn-danger m-2" onclick="{onSave}">
                            <span if="{state.step!==state.maxstep}" >Save</span>
                            <span if="{state.step==state.maxstep}">Finsih</span>
                        </button>
                        <button type="submit" class="btn-block btn-primary m-2" onclick="{onNext}" if="{state.step!==state.maxstep}">
                            Next
                            <iconify-icon icon="material-symbols:arrow-forward-ios-rounded" inline></iconify-icon>
                        </button> 
                    </div>
                </div>
            </div>



            <div class="card {cn({'hidden-card':state.step!=100})}">

                <h5 class="text-center mb-4">
                    Confirmation Number: <strong>#{state.report_number}</strong>
                </h5>

                <p>
                    Your commission/contract report has been submitted. The report number is #{state.report_number}.
                </p>
            </div>


            </form>
            
        </div>
    </div>

    <style>

        .hidden-card, .hidden-row{
            display: none;
        }
        
        .hidden-field {
            display: none !important;
        }

        .form-title {
            color:white;
        }
            .card{padding: 30px 40px;
                margin-top: 1.5em;
                margin-bottom: 60px;
                border: none !important;
                box-shadow: 0 6px 12px 0 rgba(0,0,0,0.2)
            }
            .blue-text{
                color: #00BCD4
            }
            .form-control-label{
                margin-bottom: 0
            }
            input, textarea, button{
                padding: 8px 15px;
                border-radius: 5px !important;
                margin: 5px 0px;
                box-sizing: border-box;
                border: 1px solid #ccc;
                font-size: 18px !important;
                font-weight: 300
            }
            input:focus, textarea:focus{
                -moz-box-shadow: none !important;
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
                border: 1px solid #00BCD4;
                outline-width: 0;
                font-weight: 400
            }
            .btn-block{
                font-size: 15px !important;
                font-weight: 400;
                height: 43px;
                cursor: pointer
            }
            .btn-block:hover{
                color: darkmagenta !important
            }
            button:focus{
                -moz-box-shadow: none !important;
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
                outline-width: 0
            }
    </style>

    <script>

        import { api_get } from '../../lib/api.js'
        import InputCurrency from '../inputs/input-currency.riot'
        import StepDisplay from '../controls/step-display.riot'
        import PubSub from 'pubsub-js'
        import currency from "currency.js";
        import Schema from 'async-validator';
        import {api_post, api_put} from "../../lib/api";
        import isEmpty from 'just-is-empty';
        import Swal from 'sweetalert2'
        import dayjs from "dayjs";

        export default {
            components: {
                InputCurrency,
                StepDisplay
            },
            state:{
                step:1,
                maxstep:6,
                commission_type: 'sale',
                commission_agency: 3,
                needs_factura: false,
                commission: 6,
                rental_comm_types: [
                    {
                        code: 'year',
                        name: "1 year"
                    },
                    {
                        code: 'month_6',
                        name: '6 month'
                    },
                    {
                        code: 'comm_15',
                        name: '15%'
                    },
                    {
                        code: 'other',
                        name: "Other"
                    }
                ],
                currencies: [
                    {
                        name: "USD",
                        code: "USD"

                    },
                    {
                        name: "MXN",
                        code: 'MXN'
                    }
                ],
                staff: []
            },
            onBeforeMount(props, state) {
                this.editId = props.editId || false;

                if(this.query_params.edit_id){
                    this.editId = this.query_params.edit_id;
                }

                const staffid = this.query_params.staff;

                if(staffid){
                    this.state.selected_staff = staffid;
                }
            },
            onMounted(){
                console.log('form mounted')

                this.loadStaff().then(()=>{

                    if(this.editId){
                        return this.loadCommission(this.editId);
                    }

                    return false;
                }).then(populateFrom=>{
                    if(!populateFrom){
                        return false;
                    }

                    setTimeout(()=>{
                        this.populateForm(populateFrom);
                    }, 10)
                    //return this.populateForm();
                });

            },
            populateForm(){
                console.log('populate form')
                const $ = id => this.$('#'+id);
                const c = this.state.origin;

                const easy_fields = ['for_id','contract_date','closing_date','property_address',
                                    'other_agency','commission_price','commission_total','commission',
                                    'property_ref','commission_agency','commission_agency_total',
                                    'commission_agent','commission_agent_total',
                                    'other_agency','rental_contract_type',
                                    'comments'];

                const factura_fields = ['client_name_legal','client_address_city','client_address_state',
                                        'client_address_postal','client_regimen_fiscal','client_rfc','client_address_street'];

                const dates = ['contract_date','closing_date'];

                const event_fields = ['commission_total','commission_price',
                                    'commission_agency_total','commission_agent_total'];

                const change_event = new Event('change');

                this.state.commission_type = this.commission_type = c.commission_type;


                if(c.commission_type=='rental'){
                    $('commission_type_sale').checked = false;
                    $('commission_type_rental').checked = true;
                    $('commission_type_sale').dispatchEvent(change_event);
                    this.update({
                        commission_type: 'rental'
                    });
                }else{
                    $('commission_type_sale').checked = true;
                    $('commission_type_rental').checked = false;
                    $('commission_type_sale').dispatchEvent(change_event);
                    this.update({
                        commission_type: 'sale'
                    });
                }

                if(c.other_is == 'buyer'){
                    $('other_is_buyer').checked = true;
                    $('other_is_buyer').dispatchEvent(change_event);
                }else if(c.other_is == 'seller'){
                    $('other_is_seller').checked = true;
                    $('other_is_seller').dispatchEvent(change_event);
                }else{
                    $('other_is_na').checked = true;
                    $('other_is_na').dispatchEvent(change_event);
                }

                if(c.client_needs_factura=='yes'){
                    console.log('needs factura');
                    this.update({
                        needs_factura: true
                    })
                    this.$('#factura_yes').checked=true;
                    this.$('#factura_no').checked=false;
                    this.$('#factura_yes').dispatchEvent(change_event);
                    //this.$('#factura_no').dispatchEvent(change_event);
                }else{
                    console.log('needs factura');
                    this.$('#factura_yes').checked=false;
                    this.$('#factura_no').checked=true;
                    this.$('#factura_yes').dispatchEvent(change_event);
                    //this.$('#factura_no').dispatchEvent(change_event);
                }

                if(c.commission_currency == 'USD'){
                    this.$('#currency_usd').checked = true;
                    this.$('#currency_mxn').checked = false;
                    this.$('#currency_usd').dispatchEvent(change_event);
                }else{
                    this.$('#currency_usd').checked = false;
                    this.$('#currency_mxn').checked = true;
                    this.$('#currency_mxn').dispatchEvent(change_event);
                }

                const fields = c.client_needs_factura=='yes' ? easy_fields.concat(factura_fields) : easy_fields;

                this.populate_fields(fields, c, event_fields, dates);
                /*fields.forEach(field=>{
                    const el = $(field);
                    try{
                        if(dates.includes(field)) {
                            el.value = dayjs(c[field]).format('YYYY-MM-DD');
                        }else{
                            el.value = c[field];
                        }

                        if(event_fields.includes(field)){
                            el.dispatchEvent(change_event);
                        }

                    }catch(e){
                        console.log('error populating field', field, el)
                        console.log(e);
                    }
                });*/

                if(c.client_needs_factura){
                    this.$('#factura_yes').dispatchEvent(change_event);
                    this.update({
                        needs_factura: true
                    });
                    setTimeout(()=>{
                        console.log("%c Delayed Factura Input Running", "color: #ff0000; font-size: 20px;");
                        this.populate_fields(factura_fields, c, event_fields, dates);
                    }, 500)
                }
            },
            populate_fields(fields, c, event_fields=[], dates=[]){
                const $ = id => this.$('#'+id);
                const change_event = new Event('change');

                fields.forEach(field=>{
                    const el = $(field);
                    try{

                        if(!el){
                            console.error('no element found for field', field);
                        }

                        if(dates.includes(field)) {
                            el.value = dayjs(c[field]).format('YYYY-MM-DD');
                        }else{
                            el.value = c[field];
                        }

                        if(event_fields.includes(field)){
                            el.dispatchEvent(change_event);
                        }

                    }catch(e){
                        console.log('error populating field', field, el)
                        console.log(e);
                    }
                });
            },
            loadStaff(){
                return api_get('staff/').then((res)=>{
                    if(res.status==200){
                        this.update({
                            staff: res.data
                        })
                    }
                });
            },
            loadCommission(id) {
                return api_get(`commission/${id}`).then((res) => {
                    console.log(res);
                    if (res.status == 200) {
                        this.update({
                            origin: res.data,
                        });
                        return true;
                    }

                    return false;
                });
            },
            onPreventSubmit(e){
                e.preventDefault();
                e.stopPropagation();
                return false;
            },
            onNext(){
                let curr = this.state.step;

                if(this.state.commission_type=='rental'){
                    if(curr == 3) curr=curr+2;
                    if(curr == 4) curr++;
                }

                const step = curr + 1;

                const data = this.get_data(curr);
                console.log(data);

                if(this.editId){
                    if(step > 0 && step <= this.state.maxstep){
                        this.update({step:step});
                    }
                }else{
                    this.on_start_step(step,data).then(good=>{
                        if(!good) return false;
                        if(step > 0 && step <= this.state.maxstep){
                            this.update({step:step});
                        }
                    });
                }

            },
            onBack(){
                let curr = this.state.step;

                if(this.state.commission_type=='rental'){
                    if(curr==6) curr=curr-2;
                    if(curr==5) curr--;
                }

                const step = curr - 1;

                if(step > 0 && step <= this.state.maxstep){
                    this.update({step:step});
                }
            },
            onNeedFactura(e){
                const sel = e.target.dataset.sel;
                this.update({
                    needs_factura: (sel=='1')
                });
            },
            onCommissionTypeSel(e){
                const el = this.$('#commission_type_sale');
                if(el){
                    if(el.checked) this.state.commission_type = 'sale';
                    else this.state.commission_type = 'rental';
                }else{
                    this.state.commission_type = 'sale';
                }
                this.commission_type = this.state.commission_type;
                this.update();
            },
            onCurrencyInput(name,price){
                console.log(name, price, 'Woo hoo');
                //this.customFieldData[name] = price;

                if(name=='commission_price_') this.onCalcTotalCommission();
            },
            onCalcTotalCommission(){
                console.log("onCalcTotalCommission");
                const price = this.$('#commission_price').value;
                if(!isNaN(price)){
                    console.log('Calulo Commission', price);
                    const commission = parseFloat(this.$('#commission').value);
                    if(isNaN(commission)) return;
                    if(price<1 || commission < 1) return;

                    const c_price = currency(price);
                    const commission_calc = c_price.multiply(commission/100)

                    //this.customFieldData.commission_total = commission_calc.value;

                    console.log(commission_calc.value);
                    const $comTotal = this.$('#commission_total');
                    $comTotal.value = commission_calc.value;
                    $comTotal.dispatchEvent(new Event('change'));

                    PubSub.publish('input-currency.commission_total', commission_calc.value);
                }else{
                    console.log('NOO Calulo Commission', price);
                }
            },
            onCalcAgentCommission(){
                const $commission_agent_total = this.$('#commission_agent_total');
                const agent_comm_value = this.$('#commission_agent').value;
                const agency_total_commission_value = this.$('#commission_agency_total').value;
                const agent_commission = parseFloat(agent_comm_value);
                const agency_commission = parseFloat(agency_total_commission_value);

                var changeEvent = new Event('change');

                if(isNaN(agent_commission) || isNaN(agency_commission) || agent_commission < 1) {
                    $commission_agent_total.value = 0;
                    $commission_agent_total.dispatchEvent(changeEvent);
                    return;
                }
                $commission_agent_total.value = currency(agency_commission).multiply(agent_commission/100);

                console.log("Change Event!!")

                $commission_agent_total.dispatchEvent(changeEvent);
            },
            onCalcAgencyTotal(){
                const commission_price = parseFloat(this.$('#commission_price').value);
                const commission_agency = parseFloat(this.$('#commission_agency').value);
                const type = this.state.commission_type;

                if(isNaN(commission_price)) return;
                if(type=='sale' && isNaN(commission_agency)) return;

                console.log(commission_price,commission_agency);

                const commission_total = currency(commission_price).multiply(commission_agency/100);

                const $commission_agent = this.$('#commission_agent');
                const $commission_agent_total = this.$('#commission_agency_total');

                if(type=='sale'){
                    $commission_agent_total.value = commission_total;
                    $commission_agent.value = 60;
                }else{
                    this.on_calc_rental_commission();
                    $commission_agent.value = 80;
                }

                console.log("#commission_agent change");
                $commission_agent_total.dispatchEvent(new Event('change'));
            },
            get_data(step){

                if(step=='all') step = [1,2,3,4,5,6];

                const _step = !(step instanceof Array) ? [step] : step;

                let data = {};

                const _get_fields = arr=>{
                    const data = {};
                    arr.forEach(f=>{
                        if(f[0]=='*') {
                            const f2 = f.slice(1);
                            data[f2] = this.customFieldData[f2];
                        }else if(f.slice(-5) == '_date'){
                            if(isEmpty(this.$(`#${f}`).value)){
                                data[f] = null;
                            }else{
                                data[f] = this.$(`#${f}`).valueAsDate.toISOString();
                            }

                        } else {

                            data[f] = this.$(`#${f}`).value;
                            console.log('Get ', f, ' value ', data[f]);
                        }
                    });
                    return data;
                };  

                const stepFields = [];
                stepFields[1] = ['for_id','contract_date','closing_date','property_ref',
                                    'property_address','commission_price','commission','commission_total','other_agency'];
                stepFields[2] = ['commission_agency','commission_agency_total','rental_contract_type'];
                stepFields[3] = ['commission_agent','commission_agent_total'];      
                stepFields[4] = ['expense_description_1','expense_currency_1','expense_amount_1',
                                'expense_description_2','expense_currency_2','expense_amount_2',
                                'expense_description_3','expense_currency_3','expense_amount_3'];

                _step.forEach(s => {
                    let d;
                    if(s==1){
                        d = _get_fields(stepFields[s]);
                        d.commission_currency = this.$('#currency_usd').checked ? 'USD' : 'MXN';
                        d.commission_type = this.$('#commission_type_rental').checked ? 'rental' : 'sale';
                        d.other_agency_is = this.$('#other_is_buyer').checked ? 'buyer' : this.$('#other_is_seller').checked ? 'seller' : '';
                    }else if(s==2 || s==3 || s==4){
                        d = _get_fields(stepFields[s]);
                    }else if(s==5){
                        if(this.$('#factura_yes').checked){
                            d = _get_fields(['client_name_legal','client_address_street',
                                'client_address_city','client_address_state',
                                'client_address_postal','client_rfc','client_regimen_fiscal']);
                            d.client_needs_factura = 'yes';
                        }else{
                            d = {'client_needs_factura':'no'}
                        }
                    }else if(s==6){
                        d = _get_fields(['comments']);
                    }
                    if(d) data = {...data, ...d};
                });

                return data;
            },
            onUpdateRentalCommission(){
                console.log("Calc Rental Contract");
                this.on_calc_rental_commission();
            },
            on_calc_rental_commission(){
                //TODO: This has bee turned off for now. Maybe we handle this differently in teh future.

                return;

                const rental_type = this.$("#rental_contract_type").value;
                const commission_price = currency(this.$('#commission_price').value);

                const $commission_agent_total = this.$('#commission_agency_total');

                if(rental_type == 'year'){
                    $commission_agent_total.value = commission_price.divide(12);
                }else if(rental_type == 'month_6'){
                    $commission_agent_total.value = commission_price.divide(12);
                }else if(rental_type == 'comm_15'){
                    $commission_agent_total.value = commission_price.multiply(15/100);
                }else{
                    $commission_agent_total.value = 0;
                }

                $commission_agent_total.dispatchEvent(new Event('change'));
            },
            handleErrorMessage(errors){
                let message = "";
                errors.forEach(e => {
                    message += '<p><strong>' + e.field + ":</strong> " + e.message + "</p>\n";
                });
                return Swal.fire('Validation Error', message, 'error').then(()=>{
                    return false;
                })
            },
            on_start_step(step,data){

                console.log("On NExt Step", data);

                return new Promise(resolve => {
                    if(step == 2){
                        console.log("Start step 2");

                        this.validateInput(this.convertTypes(data),1).then(()=>{
                            this.onCalcAgencyTotal();
                            resolve(true);
                        }).catch(err=>{
                            if(err.errors){
                                resolve(this.handleErrorMessage(err.errors));
                            }else{
                                Swal.fire('Error', err.message, 'error').then(()=>{
                                    resolve(false);
                                })
                            }
                        })


                    }else if(step == 3){
                        this.onCalcAgentCommission();
                        resolve(true);
                    }else{
                        resolve(true);
                    }
                })
            },
            verifyFactura(data) {

                const trimval = (value) => value.trim();

                const desc = {
                    client_name_legal: {
                        type: 'string',
                        required: true,
                        transform: trimval,
                        whitespace: true,
                        message: "Client legal name is required for factura"
                    },
                    client_rfc: {
                        type: 'string',
                        required: true,
                        transform: trimval,
                        whitespace: true,
                        message: "Client RFC is required for factura"

                    },
                    client_regimen_fiscal: {
                        type: 'string',
                        required: true,
                        transform: trimval,
                        whitespace: true,
                        message: "Client Régimen Fiscal is required"
                    },
                    client_address_street: {
                        type: 'string',
                        required: true,
                        transform: trimval,
                        whitespace: true,
                        message: "Client address is required for factura"
                    },
                    client_address_state: {
                        type: 'string',
                        require: true,
                        transform: trimval,
                        whitespace: true,
                        message: "Client address is required for factura"
                    },
                    client_address_postal: {
                        type: 'string',
                        required: true,
                        transform: trimval,
                        whitespace: true,
                        message: "Client postal code is required for factura"
                    }
                }

                const validator = new Schema(desc);
                return validator.validate(data);
            },
            validateInput(data, step = false){
                const trimval = (value) => value.trim();
                const money = ['USD', 'MXN'];
                const yesno = ['yes', 'no'];

                const basic = {
                    for_id: {
                        type: 'integer',
                        required: true,
                        message: "This report should be for somebody. Please select an agent."
                    },
                    commission_type: {
                        type: 'string',
                        required: true,
                        enum: ['sale', 'rental'],
                        message: "This must be a sales or rental commission. Please choose one."
                    },
                    rental_contract_type: {
                        type: 'string',
                        required: false,
                        enum: ['year', 'month_6', 'comm_15', 'other'],
                        message: "Rental Contract Type seems to be incorrect"
                    },
                    commission: {
                        type: 'number',
                        required: false,
                        message: "Commission must be a number"
                    },
                    contract_date: {
                        type: 'string',
                        required: true,
                        message: "Under Contract date is required."
                    },
                    closing_date: {
                        type: 'string',
                        required: true,
                        message: "Closing date is required."
                    },
                    property_ref: {
                        type: 'number',
                        required: false,
                        message: "Property Ref must be a number!"
                    },
                    commission_price: {
                        type: 'number',
                        required: true,
                        message: "Property/Rental price is require and should be a number."
                    },
                    commission_total: {
                        type: 'number',
                        required: false,
                        message: "Total commission on step 1 should be a number"
                    },
                    commission_agency: {
                        type: 'number',
                        required: false,
                        message: "Agency commission percent should be a number"
                    },
                    commission_agent_total: {
                        type: 'number',
                        required: false,
                        message: "Agency commission total should be a number"
                    },
                    commission_currency: {
                        type: 'string',
                        required: true,
                        enum: money,
                        message: "Commission currency should be set to USD or MXN."
                    },
                    client_needs_factura: {
                        type: 'string',
                        required: true,
                        enum: yesno,
                        message: "Client needs factura should be yes or no."
                    }
                }

                if(step==1){
                    delete basic.client_needs_factura;
                }

                const validator = new Schema(basic);
                return validator.validate(data);
            },
            validateRentalInput(data, step = false){
                const trimval = (value) => value.trim();
                const money = ['USD', 'MXN'];
                const yesno = ['yes', 'no'];

                const basic = {
                    for_id: {
                        type: 'integer',
                        required: true,
                        message: "This report should be for somebody. Please select an agent."
                    },
                    commission_type: {
                        type: 'string',
                        required: true,
                        enum: ['sale', 'rental'],
                        message: "This must be a sales or rental commission. Please choose one."
                    },
                    rental_contract_type: {
                        type: 'string',
                        required: false,
                        enum: ['year', 'month_6', 'comm_15', 'other'],
                        message: "Rental Contract Type seems to be incorrect"
                    },
                    commission: {
                        type: 'number',
                        required: false,
                        message: "Commission must be a number"
                    },
                    contract_date: {
                        type: 'string',
                        required: true,
                        message: "Under Contract date is required."
                    },
                    closing_date: {
                        type: 'string',
                        required: true,
                        message: "Closing date is required."
                    },
                    property_ref: {
                        type: 'number',
                        required: false,
                        message: "Property Ref must be a number!"
                    },
                    commission_price: {
                        type: 'number',
                        required: true,
                        message: "Property/Rental price is require and should be a number."
                    },
                    commission_agent_total: {
                        type: 'number',
                        required: false,
                        message: "Agency commission total should be a number"
                    },
                    commission_currency: {
                        type: 'string',
                        required: true,
                        enum: money,
                        message: "Commission currency should be set to USD or MXN."
                    },
                    client_needs_factura: {
                        type: 'string',
                        required: true,
                        enum: yesno,
                        message: "Client needs factura should be yes or no."
                    }
                }

                if(step==1){
                    delete basic.client_needs_factura;
                }

                const validator = new Schema(basic);
                return validator.validate(data);
            },
            onSave(){
                const data = this.get_data('all');
                this.convertTypes(data);

                console.log(data);

                this.validateInput(data).then(res=>{
                    console.log(res);

                    if(data.client_needs_factura == 'yes'){
                        return this.verifyFactura(data);
                    }else{
                        return true;
                    }

                })
                .then(()=>{
                    const expenses = this.removeExpense(data);
                    console.log(expenses, data);
                    return expenses;
                }).then((expenses)=>{
                    if(this.editId){
                        data.ID = parseInt(this.editId, 10);
                    }
                    return this.sendToServer(data, expenses);
                })
                .catch(err=>{
                    console.log(err.errors);
                });
            },
            convertTypes(data){

                const toInt = ['for_id', 'property_ref'];
                const toFloat = ['commission','commission_agency','commission_agent'];
                const toCurrency = ['commission_total','commission_agent_total','commission_price','commission_agency_total',
                                    'expense_amount_1','expense_amount_2','expense_amount_3']

                toInt.forEach(f=>{
                    if(data[f] && data[f]!='') data[f] = parseInt(data[f], 10);
                    else data[f] = null;
                });

                toFloat.forEach(f=>{
                    if(data[f] && data[f]!='') data[f] = parseFloat(data[f]);
                    else data[f] = null;
                })

                toCurrency.forEach(f=>{
                    if(data[f] && data[f]!='') data[f] = currency(data[f]).value;
                    else data[f] = null;
                });

                return data;
            },
            removeExpense(data){
                  const fields = ['expense_description_','expense_currency_','expense_amount_'];
                  const max = 3;

                  const expenses = [];

                  for( let i = 1; i <= max; i++){
                      const expense = {};
                      for(let x = 0; x < 3; x++){
                            const f = fields[x]+i.toString();
                            expense[f.replace('expense_','').replace(`_${i}`,'')] = data[f];
                            delete data[f];
                      }
                      expenses.push(expense);
                  }

                  return expenses;
            },
            sendToServer(data, expenses){

                let api = api_post;
                let url = 'commission/'

                if(data.ID){
                    api = api_put;
                    url = 'commission/'+this.editId;
                }

                return api(url, data).then(res=>{
                    if(res.status==200 && res.data.success==true){
                        console.log(res);
                        console.log("Success!!");

                        const id = res.data.data.ID;

                        return this.sendToServerExpenses(expenses, id).then(()=>{
                            this.update({
                                step:100,
                                finished: id,
                                report_number: String(id).padStart(5, '0')
                            });
                        });

                    }else{
                        Swal.fire("Error!","Could not send commission report! Please contact Jason.", 'error');
                    }
                });
            },
            sendToServerExpenses(expenses, id){
                const tasks = expenses.map(expense=>{
                    if(isEmpty(expense.description.trim())){
                        return Promise.resolve();
                    }
                    expense.commission_id = id;
                    return api_post('expenses/', expense);
                });

                return Promise.all(tasks);
            }
        }
    </script>
</commission-form>
