/* Riot v7.1.0, @license MIT */
import $ from '../node_modules/bianco.query/index.next.js';
import { DOM_COMPONENT_INSTANCE_PROPERTY } from '../node_modules/@riotjs/util/constants.js';

/**
 * Sweet unmounting helper function for the DOM node mounted manually by the user
 * @param   {string|HTMLElement} selector - query for the selection or a DOM element
 * @param   {boolean|null} keepRootElement - if true keep the root element
 * @returns {Array} list of nodes unmounted
 */
function unmount(selector, keepRootElement) {
  return $(selector).map(element => {
    if (element[DOM_COMPONENT_INSTANCE_PROPERTY]) {
      element[DOM_COMPONENT_INSTANCE_PROPERTY].unmount(keepRootElement);
    }
    return element;
  });
}

export { unmount };
