import {utils, writeFile} from 'xlsx';

export class ExelCreator{

  constructor(){
    this.sheets = [];
    this.wb = utils.book_new();
    if(!this.wb.Props) this.wb.Props = {};
    this.wb.Props.CreatedDate = new Date();
  }

  addProps(title=null,subject=null,author=null){
    if(title) this.wb.Props.Title = title;
    if(subject) this.wb.Props.Subject = subject;
    if(author) this.wb.Props.Author = author;
  }

  addSheet(name, data){

    this.wb.SheetNames.push(name);

    const sheetdata = {
      name: name,
      columns: false,
      data: data
    }

    this.wb.Sheets[name] = utils.json_to_sheet(sheetdata.data);

    this.sheets.push(sheetdata);
  }

  s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }

  out(filename = null){
    /*const file_name = filename ? filename :"export.xlsx";
    const wb_out = XLSX.write(this.wb, {bookType:'xlsx',  type: 'binary'});
    saveAs(new Blob([this.s2ab(wb_out)],{type:"application/octet-stream"}), file_name)*/
    writeFile(this.wb, filename);
  }

}
