/* Riot v7.1.0, @license MIT */
import { DOMattributesToObject } from '../node_modules/@riotjs/util/dom.js';
import { callOrAssign } from '../node_modules/@riotjs/util/functions.js';

/**
 * Evaluate the component properties either from its real attributes or from its initial user properties
 * @param   {HTMLElement} element - component root
 * @param   {Object}  initialProps - initial props
 * @returns {Object} component props key value pairs
 */
function computeInitialProps(element, initialProps) {
  if (initialProps === void 0) {
    initialProps = {};
  }
  return Object.assign({}, DOMattributesToObject(element), callOrAssign(initialProps));
}

export { computeInitialProps };
