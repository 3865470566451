/* Riot v7.1.0, @license MIT */
import { callOrAssign } from '../node_modules/@riotjs/util/functions.js';

/**
 * Compute the component current state merging it with its previous state
 * @param   {Object} oldState - previous state object
 * @param   {Object} newState - new state given to the `update` call
 * @returns {Object} new object state
 */
function computeComponentState(oldState, newState) {
  return Object.assign({}, oldState, callOrAssign(newState));
}

export { computeComponentState };
