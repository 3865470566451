<app>

    <div class="container-fluid">
        <div class="row flex-nowrap">
            <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark-sidebar">
                <Router>
                    <sidebar username="{state.username}" />
                </Router>
            </div>
            <div class="col py-1">
                
                <!-- Content Area -->

                <route path="/" >
                    <div class="text-center p-10">
                        <h1 class="my-3">Dashboard - Coming Soon!</h1>

                        <p if="{_user.admin}">{_user.username}</p>

                        <img if="{state.images}" src="{state.images.commingsoon}" alt="Comming Soon" class="img-fluid" style="max-height: 600px;" />

                    </div>


                </route>

                <route path="/commissions">
                    <commission-list />
                </route>

                <route path="/commission/edit/:id">
                    <commission-form edit-id="{route.params.id}" />
                </route>

                <route path="/commission/:id">
                    <commission-view cid="{route.params.id}" />
                </route>

                <route path="/sales">
                    <sale-list />
                </route>

                <route path="/agents">
                    Agent Statistics Comming Soon!
                </route>
                

            </div>
        </div>
    </div>


    <script>

        import images from "../../../../assets/img/*.jpeg";

        import Sidebar from '../../layout/sidebar.riot';
        import { Router, Route } from '@riotjs/route';
        import Cookies from 'js-cookie';

        import CommissionList from '../../commission/commission-list.riot';
        import CommissionView from '../../commission/commission-view.riot';
        import CommissionForm from '../../commission/commission-form.riot';
        import SaleList from '../../commission/sale-list.riot';

        export default {
            components: {
                Sidebar,
                Router, 
                Route,
                CommissionList,
                CommissionView,
                CommissionForm,
                SaleList,
                images: images
            },
            state: {
                name: 'World'
            },
            onBeforeMount() {
                console.log('App mounted')
                this.state.images = images;
                const t = Cookies.get('token');
                if(!t) window.location.href = '/login.html';

                this.state.username = Cookies.get('user_username');
            },
            onMounted() {
                console.log('App mounted')
            }
        }

    </script>


</app>
