

function createKey(key) {
  return `jt_cache_${key}`
}

export function setCacheItem(itemKey, itemValue, seconds=30) {
  try {
    const now = new Date();
    const jsonData = JSON.stringify({time: now, data: itemValue, seconds:seconds});
    const newKey = createKey(itemKey);
    window.localStorage.setItem(newKey, jsonData);
    return true;
  } catch(e) {
    console.error("Caching Error");
    console.log(e);
    return false;
  }
}

export function getCacheItem(itemKey) {
  try {
    const newKey = createKey(itemKey);
    const jsonObjectString = window.localStorage.getItem(newKey);

    if((!jsonObjectString) || (jsonObjectString === null)) {
      return null;
    }

    const parsedData = JSON.parse(jsonObjectString);
    const cacheDate = new Date(parsedData.time);
    const cache_seconds = parsedData.seconds;
    const n = new Date();
    const seconds = (n.getTime() - cacheDate.getTime()) / 1000;
    //console.log("cached for " + seconds + " seconds");
    if (seconds > cache_seconds) {
      return null;
    }else{
      return parsedData.data;
    }
  } catch(e) {
    return null;
  }
}

function expireOldCacheItems() {

}

