/* Riot v7.1.0, @license MIT */
import { COMPONENTS_IMPLEMENTATION_MAP } from '../node_modules/@riotjs/util/constants.js';
import { panic } from '../node_modules/@riotjs/util/misc.js';
import { createComponentFromWrapper } from '../core/create-component-from-wrapper.js';

/**
 * Register a custom tag by name
 * @param   {string} name - component name
 * @param   {Object} implementation - tag implementation
 * @returns {Map} map containing all the components implementations
 */
function register(name, _ref) {
  let {
    css,
    template,
    exports
  } = _ref;
  if (COMPONENTS_IMPLEMENTATION_MAP.has(name)) panic(`The component "${name}" was already registered`);
  COMPONENTS_IMPLEMENTATION_MAP.set(name, createComponentFromWrapper({
    name,
    css,
    template,
    exports
  }));
  return COMPONENTS_IMPLEMENTATION_MAP;
}

export { register };
