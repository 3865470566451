/* Riot v7.1.0, @license MIT */
import { COMPONENTS_IMPLEMENTATION_MAP } from '../node_modules/@riotjs/util/constants.js';
import { panic } from '../node_modules/@riotjs/util/misc.js';
import cssManager from '../core/css-manager.js';

/**
 * Unregister a riot web component
 * @param   {string} name - component name
 * @returns {Map} map containing all the components implementations
 */
function unregister(name) {
  if (!COMPONENTS_IMPLEMENTATION_MAP.has(name)) panic(`The component "${name}" was never registered`);
  COMPONENTS_IMPLEMENTATION_MAP.delete(name);
  cssManager.remove(name);
  return COMPONENTS_IMPLEMENTATION_MAP;
}

export { unregister };
