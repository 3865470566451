/* Riot v7.1.0, @license MIT */
import { PURE_COMPONENT_API } from './pure-component-api.js';
import { noop } from '../node_modules/@riotjs/util/functions.js';

const MOCKED_TEMPLATE_INTERFACE = Object.assign({}, PURE_COMPONENT_API, {
  clone: noop,
  createDOM: noop
});

export { MOCKED_TEMPLATE_INTERFACE };
