<my-hello>
    <h1>Hello {props.name}</h1>

    <script>
        export default {
            state: {
                name: 'World'
            },
            onMounted() {
                console.log('Hello mounted')
            }
        }
    </script>
</my-hello>